/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\Indian_man_middle_anim_hopak.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Indian_man_middle_anim_hopak(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/Indian_man_middle_anim_hopak-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rp_eric_rigged_001Action"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rp_eric_rigged_001" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.root} />
          <group name="f010_01-m_suit_01019">
            <skinnedMesh name="f010_01-m_suit_01019_1" geometry={nodes['f010_01-m_suit_01019_1'].geometry} material={materials.m_suit_01} skeleton={nodes['f010_01-m_suit_01019_1'].skeleton} />
            <skinnedMesh name="f010_01-m_suit_01019_2" geometry={nodes['f010_01-m_suit_01019_2'].geometry} material={materials['Material.002']} skeleton={nodes['f010_01-m_suit_01019_2'].skeleton} />
          </group>
          <group name="Loafer-3_left001">
            <skinnedMesh name="Mesh003" geometry={nodes.Mesh003.geometry} material={materials['Material #0']} skeleton={nodes.Mesh003.skeleton} />
            <skinnedMesh name="Mesh003_1" geometry={nodes.Mesh003_1.geometry} material={materials['Material #1']} skeleton={nodes.Mesh003_1.skeleton} />
            <skinnedMesh name="Mesh003_2" geometry={nodes.Mesh003_2.geometry} material={materials['Material #35']} skeleton={nodes.Mesh003_2.skeleton} />
          </group>
          <group name="Loafer-3_left002">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['Material #0']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material #1']} skeleton={nodes.Mesh001_1.skeleton} />
            <skinnedMesh name="Mesh001_2" geometry={nodes.Mesh001_2.geometry} material={materials['Material #35']} skeleton={nodes.Mesh001_2.skeleton} />
          </group>
          <group name="rp_eric_rigged_001_geo">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.rp_eric_rigged_001_mat} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh_1.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/Indian_man_middle_anim_hopak-transformed.glb')
