/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Indian_man_teenager_(headphones)_anim_Borat.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function _anim_borat(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Indian_man_teenager_(headphones)_anim_Borat-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions.rigAction.play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="H_DDS_HighRes">
            <skinnedMesh name="Mesh007" geometry={nodes.Mesh007.geometry} material={materials['Material.001']} skeleton={nodes.Mesh007.skeleton} />
            <skinnedMesh name="Mesh007_1" geometry={nodes.Mesh007_1.geometry} material={materials.Material} skeleton={nodes.Mesh007_1.skeleton} />
          </group>
          <skinnedMesh name="h_L_eye" geometry={nodes.h_L_eye.geometry} material={materials._Body} skeleton={nodes.h_L_eye.skeleton} />
          <skinnedMesh name="h_L_gland" geometry={nodes.h_L_gland.geometry} material={materials._Body} skeleton={nodes.h_L_gland.skeleton} />
          <skinnedMesh name="h_R_eye" geometry={nodes.h_R_eye.geometry} material={materials._Body} skeleton={nodes.h_R_eye.skeleton} />
          <skinnedMesh name="h_R_gland" geometry={nodes.h_R_gland.geometry} material={materials._Body} skeleton={nodes.h_R_gland.skeleton} />
          <skinnedMesh name="Jeans" geometry={nodes.Jeans.geometry} material={materials.Jeans} skeleton={nodes.Jeans.skeleton} />
          <group name="leather_shoes_3dsky">
            <skinnedMesh name="leather_shoes_3dsky_1" geometry={nodes.leather_shoes_3dsky_1.geometry} material={materials.wire_204204204} skeleton={nodes.leather_shoes_3dsky_1.skeleton} />
            <skinnedMesh name="leather_shoes_3dsky_2" geometry={nodes.leather_shoes_3dsky_2.geometry} material={materials['Material.002']} skeleton={nodes.leather_shoes_3dsky_2.skeleton} />
          </group>
          <group name="leather_shoes_3dsky001">
            <skinnedMesh name="leather_shoes_3dsky001_1" geometry={nodes.leather_shoes_3dsky001_1.geometry} material={materials.wire_204204204} skeleton={nodes.leather_shoes_3dsky001_1.skeleton} />
            <skinnedMesh name="leather_shoes_3dsky001_2" geometry={nodes.leather_shoes_3dsky001_2.geometry} material={materials['Material.002']} skeleton={nodes.leather_shoes_3dsky001_2.skeleton} />
          </group>
          <skinnedMesh name="Object002" geometry={nodes.Object002.geometry} material={materials['Material #32']} skeleton={nodes.Object002.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Indian_man_teenager_(headphones)_anim_Borat-transformed.glb')
