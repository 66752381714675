/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\European_woman_teenager_anim_other.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function European_woman_teenager_anim_other(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/European_woman_teenager_anim_other-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="BettyHAIR">
            <skinnedMesh name="BettyOriginal_Hair" geometry={nodes.BettyOriginal_Hair.geometry} material={materials.Hair} skeleton={nodes.BettyOriginal_Hair.skeleton} />
            <skinnedMesh name="BettyOriginal_Hair_1" geometry={nodes.BettyOriginal_Hair_1.geometry} material={materials.OutfitDefault} skeleton={nodes.BettyOriginal_Hair_1.skeleton} />
          </group>
          <skinnedMesh name="Boot_L" geometry={nodes.Boot_L.geometry} material={materials.Clothing} skeleton={nodes.Boot_L.skeleton} />
          <skinnedMesh name="Boot_L001" geometry={nodes.Boot_L001.geometry} material={materials.Clothing} skeleton={nodes.Boot_L001.skeleton} />
          <group name="CC_Base_Body">
            <skinnedMesh name="CC_Base_Body_1" geometry={nodes.CC_Base_Body_1.geometry} material={materials['Std_Skin_Head.001']} skeleton={nodes.CC_Base_Body_1.skeleton} />
            <skinnedMesh name="CC_Base_Body_2" geometry={nodes.CC_Base_Body_2.geometry} material={materials['Std_Skin_Body.001']} skeleton={nodes.CC_Base_Body_2.skeleton} />
            <skinnedMesh name="CC_Base_Body_3" geometry={nodes.CC_Base_Body_3.geometry} material={materials['Std_Skin_Arm.001']} skeleton={nodes.CC_Base_Body_3.skeleton} />
            <skinnedMesh name="CC_Base_Body_4" geometry={nodes.CC_Base_Body_4.geometry} material={materials['Std_Skin_Leg.001']} skeleton={nodes.CC_Base_Body_4.skeleton} />
            <skinnedMesh name="CC_Base_Body_5" geometry={nodes.CC_Base_Body_5.geometry} material={materials.UniformSkirt} skeleton={nodes.CC_Base_Body_5.skeleton} />
          </group>
          <skinnedMesh name="CC_Base_Eye" geometry={nodes.CC_Base_Eye.geometry} material={materials['Cornea.001']} skeleton={nodes.CC_Base_Eye.skeleton} />
          <skinnedMesh name="ShirtTop" geometry={nodes.ShirtTop.geometry} material={materials.UniformTop} skeleton={nodes.ShirtTop.skeleton} />
          <skinnedMesh name="Skirt" geometry={nodes.Skirt.geometry} material={materials.UniformSkirt} skeleton={nodes.Skirt.skeleton} />
          <skinnedMesh name="Tie" geometry={nodes.Tie.geometry} material={materials.UniformTie} skeleton={nodes.Tie.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/European_woman_teenager_anim_other-transformed.glb')
