/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Latin_woman_old_anim_accordion.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Latin_woman_old_anim_accordion(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Latin_woman_old_anim_accordion-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["Animation"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="Jacket_low" geometry={nodes.Jacket_low.geometry} material={materials.Jacket_shad} skeleton={nodes.Jacket_low.skeleton} />
          <skinnedMesh name="Pants_low" geometry={nodes.Pants_low.geometry} material={materials.Pants_sha} skeleton={nodes.Pants_low.skeleton} />
          <group name="rp_alison_rigged_001_geo">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.rp_alison_rigged_001_mat} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials.Material} skeleton={nodes.Mesh_1.skeleton} />
            <skinnedMesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials['Material.001']} skeleton={nodes.Mesh_2.skeleton} />
          </group>
          <skinnedMesh name="Sando_low" geometry={nodes.Sando_low.geometry} material={materials.Sando_Sha} skeleton={nodes.Sando_low.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Latin_woman_old_anim_accordion-transformed.glb')
