/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\African_woman_young_anim_hopak.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function African_woman_young_anim_hopak(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/African_woman_young_anim_hopak-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rp_alison_rigged_001Action"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rp_alison_rigged_001" scale={0.01}>
          <primitive object={nodes.root} />
          <group name="rp_alison_rigged_001_geo">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.rp_alison_rigged_001_mat} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh_1.skeleton} />
          </group>
          <group name="uploads_files_3074055_REDHEAD+OBJ001">
            <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ001_1" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ001_1'].geometry} material={materials['SneakersSG.002']} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ001_1'].skeleton} />
            <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ001_2" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ001_2'].geometry} material={materials['SneakersSG1.002']} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ001_2'].skeleton} />
            <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ001_3" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ001_3'].geometry} material={materials.Sneakers002} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ001_3'].skeleton} />
            <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ001_4" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ001_4'].geometry} material={materials['G8FPaigeSneakersSG27.002']} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ001_4'].skeleton} />
          </group>
          <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ047" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ047'].geometry} material={materials.Material} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ047'].skeleton} />
          <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ064" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ064'].geometry} material={nodes['uploads_files_3074055_REDHEAD+OBJ064'].material} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ064'].skeleton} />
          <group name="uploads_files_3074055_REDHEAD+OBJ097">
            <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ097_1" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ097_1'].geometry} material={materials['SneakersSG.002']} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ097_1'].skeleton} />
            <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ097_2" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ097_2'].geometry} material={materials['SneakersSG1.002']} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ097_2'].skeleton} />
            <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ097_3" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ097_3'].geometry} material={materials.Sneakers002} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ097_3'].skeleton} />
            <skinnedMesh name="uploads_files_3074055_REDHEAD+OBJ097_4" geometry={nodes['uploads_files_3074055_REDHEAD+OBJ097_4'].geometry} material={materials['G8FPaigeSneakersSG27.002']} skeleton={nodes['uploads_files_3074055_REDHEAD+OBJ097_4'].skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/African_woman_young_anim_hopak-transformed.glb')
