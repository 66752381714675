/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Indian_woman_teenager_(bag)_anim_gangnam.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function _anim_gangnam(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Indian_woman_teenager_(bag)_anim_gangnam-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions.rp_alison_rigged_001Action.play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rp_alison_rigged_001" scale={0.01}>
          <primitive object={nodes.root} />
          <skinnedMesh name="body012" geometry={nodes.body012.geometry} material={materials.skirt} skeleton={nodes.body012.skeleton} />
          <skinnedMesh name="jacket" geometry={nodes.jacket.geometry} material={materials['Material.002']} skeleton={nodes.jacket.skeleton} />
          <group name="LEFT">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials.wire_224086086} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials.wire_087224198} skeleton={nodes.Mesh001_1.skeleton} />
            <skinnedMesh name="Mesh001_2" geometry={nodes.Mesh001_2.geometry} material={materials['Material.003']} skeleton={nodes.Mesh001_2.skeleton} />
          </group>
          <group name="LEFT001">
            <skinnedMesh name="Mesh002" geometry={nodes.Mesh002.geometry} material={materials.wire_087224198} skeleton={nodes.Mesh002.skeleton} />
            <skinnedMesh name="Mesh002_1" geometry={nodes.Mesh002_1.geometry} material={materials.wire_224086086} skeleton={nodes.Mesh002_1.skeleton} />
            <skinnedMesh name="Mesh002_2" geometry={nodes.Mesh002_2.geometry} material={materials['Material.003']} skeleton={nodes.Mesh002_2.skeleton} />
          </group>
          <group name="rp_alison_rigged_001_geo">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.rp_alison_rigged_001_mat} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh_1.skeleton} />
          </group>
          <group name="uploads_files_2871727_bags2093">
            <skinnedMesh name="uploads_files_2871727_bags2093_1" geometry={nodes.uploads_files_2871727_bags2093_1.geometry} material={materials.wire_087224198} skeleton={nodes.uploads_files_2871727_bags2093_1.skeleton} />
            <skinnedMesh name="uploads_files_2871727_bags2093_2" geometry={nodes.uploads_files_2871727_bags2093_2.geometry} material={materials.wire_224086086} skeleton={nodes.uploads_files_2871727_bags2093_2.skeleton} />
            <skinnedMesh name="uploads_files_2871727_bags2093_3" geometry={nodes.uploads_files_2871727_bags2093_3.geometry} material={materials.wire_224086086} skeleton={nodes.uploads_files_2871727_bags2093_3.skeleton} />
            <skinnedMesh name="uploads_files_2871727_bags2093_4" geometry={nodes.uploads_files_2871727_bags2093_4.geometry} material={materials.wire_087224198} skeleton={nodes.uploads_files_2871727_bags2093_4.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Indian_woman_teenager_(bag)_anim_gangnam-transformed.glb')
