/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\Arab_woman_teenager_anim_hopak.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Arab_woman_teenager_anim_hopak(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/Arab_woman_teenager_anim_hopak-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["ArmatureAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" scale={0.01}>
          <primitive object={nodes.CC_Base_BoneRoot} />
          <group name="CC_Base_Body">
            <skinnedMesh name="CC_Base_Body_1" geometry={nodes.CC_Base_Body_1.geometry} material={materials['Std_Skin_Head.001']} skeleton={nodes.CC_Base_Body_1.skeleton} />
            <skinnedMesh name="CC_Base_Body_2" geometry={nodes.CC_Base_Body_2.geometry} material={materials['Std_Skin_Body.001']} skeleton={nodes.CC_Base_Body_2.skeleton} />
            <skinnedMesh name="CC_Base_Body_3" geometry={nodes.CC_Base_Body_3.geometry} material={materials['Std_Skin_Arm.001']} skeleton={nodes.CC_Base_Body_3.skeleton} />
            <skinnedMesh name="CC_Base_Body_4" geometry={nodes.CC_Base_Body_4.geometry} material={materials['Std_Skin_Leg.001']} skeleton={nodes.CC_Base_Body_4.skeleton} />
            <skinnedMesh name="CC_Base_Body_5" geometry={nodes.CC_Base_Body_5.geometry} material={materials['Std_Nails.001']} skeleton={nodes.CC_Base_Body_5.skeleton} />
          </group>
          <skinnedMesh name="CC_Base_Eye" geometry={nodes.CC_Base_Eye.geometry} material={materials['Cornea.001']} skeleton={nodes.CC_Base_Eye.skeleton} />
          <group name="desirefxme_011001">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['Material #55']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material #56']} skeleton={nodes.Mesh001_1.skeleton} />
          </group>
          <group name="desirefxme_011012">
            <skinnedMesh name="Mesh056" geometry={nodes.Mesh056.geometry} material={materials['Material #55']} skeleton={nodes.Mesh056.skeleton} />
            <skinnedMesh name="Mesh056_1" geometry={nodes.Mesh056_1.geometry} material={materials['Material #56']} skeleton={nodes.Mesh056_1.skeleton} />
          </group>
          <skinnedMesh name="meya190" geometry={nodes.meya190.geometry} material={materials.Rocker_Jeans} skeleton={nodes.meya190.skeleton} />
          <skinnedMesh name="ms_shirt_women" geometry={nodes.ms_shirt_women.geometry} material={materials.Material} skeleton={nodes.ms_shirt_women.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/Arab_woman_teenager_anim_hopak-transformed.glb')
