/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Latin_woman_young_anim_gangnam.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Latin_woman_young_anim_gangnam(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Latin_woman_young_anim_gangnam-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["ArmatureAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" scale={0.01}>
          <primitive object={nodes.CC_Base_BoneRoot} />
          <primitive object={nodes.IK_pole_l} />
          <primitive object={nodes.Bone} />
          <primitive object={nodes.IK_pole_r} />
          <primitive object={nodes.Bone001} />
          <skinnedMesh name="CC_Base_Eye" geometry={nodes.CC_Base_Eye.geometry} material={materials['Cornea.001']} skeleton={nodes.CC_Base_Eye.skeleton} />
          <skinnedMesh name="Female3_2058" geometry={nodes.Female3_2058.geometry} material={materials['Material__0.001']} skeleton={nodes.Female3_2058.skeleton} />
          <group name="Left_loafer">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials['03___Default']} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials['Material.003']} skeleton={nodes.Mesh_1.skeleton} />
          </group>
          <group name="Left_loafer001">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['03___Default']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.003']} skeleton={nodes.Mesh001_1.skeleton} />
          </group>
          <skinnedMesh name="pant_Plane003" geometry={nodes.pant_Plane003.geometry} material={materials['Material.002']} skeleton={nodes.pant_Plane003.skeleton} />
          <skinnedMesh name="shirt_Plane002" geometry={nodes.shirt_Plane002.geometry} material={materials.None} skeleton={nodes.shirt_Plane002.skeleton} />
          <group name="CC_Base_Body">
            <skinnedMesh name="CC_Base_Body_1" geometry={nodes.CC_Base_Body_1.geometry} material={materials['Std_Skin_Head.001']} skeleton={nodes.CC_Base_Body_1.skeleton} morphTargetDictionary={nodes.CC_Base_Body_1.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_1.morphTargetInfluences} />
            <skinnedMesh name="CC_Base_Body_2" geometry={nodes.CC_Base_Body_2.geometry} material={materials['Std_Skin_Body.001']} skeleton={nodes.CC_Base_Body_2.skeleton} morphTargetDictionary={nodes.CC_Base_Body_2.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_2.morphTargetInfluences} />
            <skinnedMesh name="CC_Base_Body_3" geometry={nodes.CC_Base_Body_3.geometry} material={materials['Std_Skin_Arm.001']} skeleton={nodes.CC_Base_Body_3.skeleton} morphTargetDictionary={nodes.CC_Base_Body_3.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_3.morphTargetInfluences} />
            <skinnedMesh name="CC_Base_Body_4" geometry={nodes.CC_Base_Body_4.geometry} material={materials['Std_Skin_Leg.001']} skeleton={nodes.CC_Base_Body_4.skeleton} morphTargetDictionary={nodes.CC_Base_Body_4.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_4.morphTargetInfluences} />
            <skinnedMesh name="CC_Base_Body_5" geometry={nodes.CC_Base_Body_5.geometry} material={materials['Std_Nails.001']} skeleton={nodes.CC_Base_Body_5.skeleton} morphTargetDictionary={nodes.CC_Base_Body_5.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_5.morphTargetInfluences} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Latin_woman_young_anim_gangnam-transformed.glb')
