/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_part2\Arabic_woman_old_anim_chickendance.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Arabic_woman_old_anim_chickendance(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/Arabic_woman_old_anim_chickendance-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction.001"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="CC_Base_Body">
            <skinnedMesh name="CC_Base_Body_1" geometry={nodes.CC_Base_Body_1.geometry} material={materials['Std_Skin_Head.001']} skeleton={nodes.CC_Base_Body_1.skeleton} />
            <skinnedMesh name="CC_Base_Body_2" geometry={nodes.CC_Base_Body_2.geometry} material={materials['Std_Skin_Body.001']} skeleton={nodes.CC_Base_Body_2.skeleton} />
            <skinnedMesh name="CC_Base_Body_3" geometry={nodes.CC_Base_Body_3.geometry} material={materials['Std_Skin_Arm.001']} skeleton={nodes.CC_Base_Body_3.skeleton} />
            <skinnedMesh name="CC_Base_Body_4" geometry={nodes.CC_Base_Body_4.geometry} material={materials['Std_Skin_Leg.001']} skeleton={nodes.CC_Base_Body_4.skeleton} />
            <skinnedMesh name="CC_Base_Body_5" geometry={nodes.CC_Base_Body_5.geometry} material={materials['Std_Nails.001']} skeleton={nodes.CC_Base_Body_5.skeleton} />
          </group>
          <skinnedMesh name="CC_Base_Eye" geometry={nodes.CC_Base_Eye.geometry} material={materials['Cornea.001']} skeleton={nodes.CC_Base_Eye.skeleton} />
          <group name="CC_Base_Teeth">
            <skinnedMesh name="CC_Base_Teeth001" geometry={nodes.CC_Base_Teeth001.geometry} material={materials['Std_Upper_Teeth.002']} skeleton={nodes.CC_Base_Teeth001.skeleton} />
            <skinnedMesh name="CC_Base_Teeth001_1" geometry={nodes.CC_Base_Teeth001_1.geometry} material={materials['Std_Lower_Teeth.002']} skeleton={nodes.CC_Base_Teeth001_1.skeleton} />
          </group>
          <group name="Plane004">
            <skinnedMesh name="Mesh406" geometry={nodes.Mesh406.geometry} material={materials.lak} skeleton={nodes.Mesh406.skeleton} />
            <skinnedMesh name="Mesh406_1" geometry={nodes.Mesh406_1.geometry} material={materials['Muntz Metal']} skeleton={nodes.Mesh406_1.skeleton} />
          </group>
          <group name="Plane004001">
            <skinnedMesh name="Mesh002" geometry={nodes.Mesh002.geometry} material={materials.lak} skeleton={nodes.Mesh002.skeleton} />
            <skinnedMesh name="Mesh002_1" geometry={nodes.Mesh002_1.geometry} material={materials['Muntz Metal']} skeleton={nodes.Mesh002_1.skeleton} />
          </group>
          <skinnedMesh name="Umbridge001" geometry={nodes.Umbridge001.geometry} material={materials.dress_mat} skeleton={nodes.Umbridge001.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/Arabic_woman_old_anim_chickendance-transformed.glb')
