/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\African_girl_baby_anim_bodilymaneuvers.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function African_girl_baby_anim_bodilymaneuvers(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/African_girl_baby_anim_bodilymaneuvers-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="Body_16169_Shape">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.Body} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_1.skeleton} />
            <skinnedMesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_2.skeleton} />
            <skinnedMesh name="Mesh_3" geometry={nodes.Mesh_3.geometry} material={materials['Material.001']} skeleton={nodes.Mesh_3.skeleton} />
          </group>
          <group name="Dress_grey">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials.Fabric1} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.002']} skeleton={nodes.Mesh001_1.skeleton} />
          </group>
          <skinnedMesh name="EyeReflection_370_Shape" geometry={nodes.EyeReflection_370_Shape.geometry} material={materials.Material} skeleton={nodes.EyeReflection_370_Shape.skeleton} />
          <skinnedMesh name="Kids-left001" geometry={nodes['Kids-left001'].geometry} material={materials['Material.003']} skeleton={nodes['Kids-left001'].skeleton} />
          <skinnedMesh name="Kids-left311" geometry={nodes['Kids-left311'].geometry} material={materials['Material.003']} skeleton={nodes['Kids-left311'].skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/African_girl_baby_anim_bodilymaneuvers-transformed.glb')
