/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Arab_man_young_anim_gangam.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Arab_man_young_anim_gangam(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Arab_man_young_anim_gangam-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions.ArmatureAction.play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rp_nathan_animated_003_walking" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="rp_nathan_animated_003_walking_CTRL">
            <group name="Armature">
              <primitive object={nodes.rp_nathan_animated_003_walking_root} />
              <skinnedMesh name="OBJ_shorts_panama_sub_lvl_2184" geometry={nodes.OBJ_shorts_panama_sub_lvl_2184.geometry} material={materials.Polyester_Taffeta_fus_rig_FRONT_3358784} skeleton={nodes.OBJ_shorts_panama_sub_lvl_2184.skeleton} />
              <skinnedMesh name="OBJ_shorts_panama_sub_lvl_2234" geometry={nodes.OBJ_shorts_panama_sub_lvl_2234.geometry} material={materials.Polyester_Taffeta_fus_rig_FRONT_3358784} skeleton={nodes.OBJ_shorts_panama_sub_lvl_2234.skeleton} />
              <skinnedMesh name="rb1001" geometry={nodes.rb1001.geometry} material={materials['Material.002']} skeleton={nodes.rb1001.skeleton} />
              <skinnedMesh name="rb1003" geometry={nodes.rb1003.geometry} material={materials['Material.002']} skeleton={nodes.rb1003.skeleton} />
              <group name="rp_nathan_animated_003_walking_geo">
                <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['rp_nathan_animated_003_mat.001']} skeleton={nodes.Mesh001.skeleton} />
                <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh001_1.skeleton} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Arab_man_young_anim_gangam-transformed.glb')
