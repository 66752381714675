/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Cat_Pose_anim_dab_cycled.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Cat_Pose_anim_dab_cycled(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Cat_Pose_anim_dab_cycled-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <skinnedMesh name="Merged_Cat_Painted1001" geometry={nodes.Merged_Cat_Painted1001.geometry} material={materials['Merged_Cat_Painted1.002']} skeleton={nodes.Merged_Cat_Painted1001.skeleton} />
          <group name="Merged_Cat_Painted1008">
            <skinnedMesh name="Merged_Cat_Painted1008_1" geometry={nodes.Merged_Cat_Painted1008_1.geometry} material={materials['Merged_Cat_Painted1.003']} skeleton={nodes.Merged_Cat_Painted1008_1.skeleton} />
            <skinnedMesh name="Merged_Cat_Painted1008_2" geometry={nodes.Merged_Cat_Painted1008_2.geometry} material={materials.teeth} skeleton={nodes.Merged_Cat_Painted1008_2.skeleton} />
            <skinnedMesh name="Merged_Cat_Painted1008_3" geometry={nodes.Merged_Cat_Painted1008_3.geometry} material={materials.claws} skeleton={nodes.Merged_Cat_Painted1008_3.skeleton} />
            <skinnedMesh name="Merged_Cat_Painted1008_4" geometry={nodes.Merged_Cat_Painted1008_4.geometry} material={materials.mustache} skeleton={nodes.Merged_Cat_Painted1008_4.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Cat_Pose_anim_dab_cycled-transformed.glb')
