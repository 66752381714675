/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\African_man_young_anim_gangnam.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function African_man_young_anim_gangnam(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/African_man_young_anim_gangnam-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions.ReferenceAction.play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="master" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="Reference">
            <primitive object={nodes.Hips} />
            <skinnedMesh name="01450" geometry={nodes['01450'].geometry} material={materials.wire_087224198} skeleton={nodes['01450'].skeleton} />
            <group name="H_DDS_HighRes">
              <skinnedMesh name="Mesh007" geometry={nodes.Mesh007.geometry} material={materials['Material.001']} skeleton={nodes.Mesh007.skeleton} />
              <skinnedMesh name="Mesh007_1" geometry={nodes.Mesh007_1.geometry} material={materials.Material} skeleton={nodes.Mesh007_1.skeleton} />
            </group>
            <skinnedMesh name="h_L_eye" geometry={nodes.h_L_eye.geometry} material={materials._Body} skeleton={nodes.h_L_eye.skeleton} />
            <skinnedMesh name="h_L_gland" geometry={nodes.h_L_gland.geometry} material={materials._Body} skeleton={nodes.h_L_gland.skeleton} />
            <skinnedMesh name="h_R_eye" geometry={nodes.h_R_eye.geometry} material={materials._Body} skeleton={nodes.h_R_eye.skeleton} />
            <skinnedMesh name="h_R_gland" geometry={nodes.h_R_gland.geometry} material={materials._Body} skeleton={nodes.h_R_gland.skeleton} />
            <skinnedMesh name="h_TeethDown" geometry={nodes.h_TeethDown.geometry} material={materials._Body} skeleton={nodes.h_TeethDown.skeleton} />
            <skinnedMesh name="h_TeethUp" geometry={nodes.h_TeethUp.geometry} material={materials._Body} skeleton={nodes.h_TeethUp.skeleton} />
            <skinnedMesh name="Object002" geometry={nodes.Object002.geometry} material={materials['Material #32']} skeleton={nodes.Object002.skeleton} />
            <group name="Shoe-Fabric001">
              <skinnedMesh name="Plane005" geometry={nodes.Plane005.geometry} material={materials['Shoe-Plastic']} skeleton={nodes.Plane005.skeleton} />
              <skinnedMesh name="Plane005_1" geometry={nodes.Plane005_1.geometry} material={materials.Rubber} skeleton={nodes.Plane005_1.skeleton} />
              <skinnedMesh name="Plane005_2" geometry={nodes.Plane005_2.geometry} material={materials['Fabric-Black']} skeleton={nodes.Plane005_2.skeleton} />
              <skinnedMesh name="Plane005_3" geometry={nodes.Plane005_3.geometry} material={materials['Shoe-Plastic']} skeleton={nodes.Plane005_3.skeleton} />
            </group>
            <group name="Shoe-Fabric002">
              <skinnedMesh name="Plane001" geometry={nodes.Plane001.geometry} material={materials.fabric} skeleton={nodes.Plane001.skeleton} />
              <skinnedMesh name="Plane001_1" geometry={nodes.Plane001_1.geometry} material={materials.Rubber} skeleton={nodes.Plane001_1.skeleton} />
              <skinnedMesh name="Plane001_2" geometry={nodes.Plane001_2.geometry} material={materials['Fabric-Black']} skeleton={nodes.Plane001_2.skeleton} />
              <skinnedMesh name="Plane001_3" geometry={nodes.Plane001_3.geometry} material={materials['Shoe-Plastic']} skeleton={nodes.Plane001_3.skeleton} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/African_man_young_anim_gangnam-transformed.glb')
