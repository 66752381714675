/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Asian_man_teenager_(hat)_anim_accordion.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function _anim_accordion(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Asian_man_teenager_(hat)_anim_accordion-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions.Animation.play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="boot" geometry={nodes.boot.geometry} material={materials.wire_086086086} skeleton={nodes.boot.skeleton} />
          <skinnedMesh name="boot2" geometry={nodes.boot2.geometry} material={materials.wire_086086086} skeleton={nodes.boot2.skeleton} />
          <skinnedMesh name="pants" geometry={nodes.pants.geometry} material={materials['Material.003']} skeleton={nodes.pants.skeleton} />
          <group name="rp_nathan_animated_003_walking_geo">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['rp_nathan_animated_003_mat.001']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh001_1.skeleton} />
            <skinnedMesh name="Mesh001_2" geometry={nodes.Mesh001_2.geometry} material={materials['Material.002']} skeleton={nodes.Mesh001_2.skeleton} />
          </group>
          <group name="tshirt">
            <skinnedMesh name="Mesh002" geometry={nodes.Mesh002.geometry} material={materials['Material.001']} skeleton={nodes.Mesh002.skeleton} />
            <skinnedMesh name="Mesh002_1" geometry={nodes.Mesh002_1.geometry} material={materials.initialShadingGroup} skeleton={nodes.Mesh002_1.skeleton} />
            <skinnedMesh name="Mesh002_2" geometry={nodes.Mesh002_2.geometry} material={materials['Material.004']} skeleton={nodes.Mesh002_2.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Asian_man_teenager_(hat)_anim_accordion-transformed.glb')
