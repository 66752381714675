/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Cat_sofa.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Cat_sofa(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Cat_sofa-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <skinnedMesh name="Merged_Cat_Painted1001" geometry={nodes.Merged_Cat_Painted1001.geometry} material={materials['Merged_Cat_Painted1.002']} skeleton={nodes.Merged_Cat_Painted1001.skeleton} />
          <group name="Merged_Cat_Painted1006">
            <skinnedMesh name="Merged_Cat_Painted1006_1" geometry={nodes.Merged_Cat_Painted1006_1.geometry} material={materials['Merged_Cat_Painted1.001']} skeleton={nodes.Merged_Cat_Painted1006_1.skeleton} />
            <skinnedMesh name="Merged_Cat_Painted1006_2" geometry={nodes.Merged_Cat_Painted1006_2.geometry} material={materials['Material.002']} skeleton={nodes.Merged_Cat_Painted1006_2.skeleton} />
          </group>
        </group>
        <group name="Sofa_Bottom">
          <mesh name="Cube001" geometry={nodes.Cube001.geometry} material={materials.Sofa_Bottom} />
          <mesh name="Cube001_1" geometry={nodes.Cube001_1.geometry} material={materials.Sofa_Base} />
          <mesh name="Cube001_2" geometry={nodes.Cube001_2.geometry} material={materials.Sofa_Patern} />
          <mesh name="Cube001_3" geometry={nodes.Cube001_3.geometry} material={materials.Pillows} />
        </group>
        <group name="tvStand">
          <mesh name="tvStand_1" geometry={nodes.tvStand_1.geometry} material={materials.Wenge_} />
          <mesh name="tvStand_2" geometry={nodes.tvStand_2.geometry} material={materials.Tv_Unit_legs} />
        </group>
        <group name="TV">
          <mesh name="Cube002" geometry={nodes.Cube002.geometry} material={materials.Main} />
          <mesh name="Cube002_1" geometry={nodes.Cube002_1.geometry} material={materials.Screen} />
          <mesh name="Cube002_2" geometry={nodes.Cube002_2.geometry} material={materials.black_plastic} />
          <mesh name="Cube002_3" geometry={nodes.Cube002_3.geometry} material={materials['metallight_Materials_Walls_Paints_Peeling_Paint_COLOR.png']} />
        </group>
        <mesh name="Bottle" geometry={nodes.Bottle.geometry} material={materials.glass} />
        <mesh name="Bottle001" geometry={nodes.Bottle001.geometry} material={materials.glass} />
        <mesh name="Bottle002" geometry={nodes.Bottle002.geometry} material={materials.glass} />
        <mesh name="Bottle003" geometry={nodes.Bottle003.geometry} material={materials.glass} />
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Cat_sofa-transformed.glb')
