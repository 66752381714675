/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\Latin_girl_baby_anim_other.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Latin_girl_baby_anim_other(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/Latin_girl_baby_anim_other-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="Body_16169_Shape">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.Body} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials.Body} skeleton={nodes.Mesh_1.skeleton} />
            <skinnedMesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials['Material.013']} skeleton={nodes.Mesh_2.skeleton} />
            <skinnedMesh name="Mesh_3" geometry={nodes.Mesh_3.geometry} material={materials['Material.001']} skeleton={nodes.Mesh_3.skeleton} />
          </group>
          <skinnedMesh name="CanvasShoes_L" geometry={nodes.CanvasShoes_L.geometry} material={materials.ChuckTaylors} skeleton={nodes.CanvasShoes_L.skeleton} />
          <skinnedMesh name="CanvasShoes_L001" geometry={nodes.CanvasShoes_L001.geometry} material={materials.ChuckTaylors} skeleton={nodes.CanvasShoes_L001.skeleton} />
          <skinnedMesh name="EyeReflection_370_Shape" geometry={nodes.EyeReflection_370_Shape.geometry} material={materials.Material} skeleton={nodes.EyeReflection_370_Shape.skeleton} />
          <group name="MateriaBeads">
            <skinnedMesh name="Mesh016" geometry={nodes.Mesh016.geometry} material={materials.TwinTailsBeads} skeleton={nodes.Mesh016.skeleton} />
            <skinnedMesh name="Mesh016_1" geometry={nodes.Mesh016_1.geometry} material={materials.TwinTailBangs} skeleton={nodes.Mesh016_1.skeleton} />
            <skinnedMesh name="Mesh016_2" geometry={nodes.Mesh016_2.geometry} material={materials.TwinTailInner} skeleton={nodes.Mesh016_2.skeleton} />
          </group>
          <group name="Skirt001">
            <skinnedMesh name="ballet_bow001" geometry={nodes.ballet_bow001.geometry} material={materials['Material.007']} skeleton={nodes.ballet_bow001.skeleton} />
            <skinnedMesh name="ballet_bow001_1" geometry={nodes.ballet_bow001_1.geometry} material={materials['Material.004']} skeleton={nodes.ballet_bow001_1.skeleton} />
            <skinnedMesh name="ballet_bow001_2" geometry={nodes.ballet_bow001_2.geometry} material={materials['Material.003']} skeleton={nodes.ballet_bow001_2.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/Latin_girl_baby_anim_other-transformed.glb')
