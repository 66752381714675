
import React , { useRef, useState, useEffect,useCallback } from "react";

import recorder from 'react-canvas-recorder';
import { saveAs } from 'file-saver';

import html2canvas from 'html2canvas';

import styled from "styled-components";

import "@tensorflow/tfjs-backend-cpu";
import "@tensorflow/tfjs-backend-webgl";
import * as cocoSsd from "@tensorflow-models/coco-ssd";

import * as network from "@tensorflow-models/mobilenet";


import { Canvas } from "@react-three/fiber";
import { useLoader,extend, useThree, useFrame   } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Suspense } from "react";
import { data, image } from "@tensorflow/tfjs";

import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

import { useGLTF, useAnimations } from '@react-three/drei'


import { CAT_BREAKDANCE } from "./jsx/CAT_BREAKDANCE.jsx";

import { African_man_young_anim_gangnam } from './jsx/African_man_young_anim_gangnam.jsx'
import { Arab_man_young_anim_gangam } from './jsx/Arab_man_young_anim_gangam.jsx'
import { Arab_woman_middle_anim_accordion } from './jsx/Arab_woman_middle_anim_accordion.jsx'
import { Asian_man_middle_anim_Borat } from './jsx/Asian_man_middle_anim_Borat.jsx'
import { Asian_man_old_anim_gangnam } from './jsx/Asian_man_old_anim_gangnam.jsx'

import { Cat_Pose_anim_baguette } from './jsx/Cat_Pose_anim_baguette.jsx'
import { Cat_Pose_anim_box } from './jsx/Cat_Pose_anim_box.jsx'
import { Cat_Pose_anim_breacdance } from './jsx/Cat_Pose_anim_breacdance.jsx'
import { Cat_Pose_anim_breacdance2 } from './jsx/Cat_Pose_anim_breacdance2.jsx'
import { Cat_Pose_anim_dab_cycled } from './jsx/Cat_Pose_anim_dab_cycled.jsx'
import { Cat_Pose_anim_dab_ended } from './jsx/Cat_Pose_anim_dab_ended.jsx'
import { Cat_Pose_anim_drunk_master } from './jsx/Cat_Pose_anim_drunk_master.jsx'
import { Cat_Pose_cofee } from './jsx/Cat_Pose_cofee.jsx'
import { Cat_sofa } from './jsx/Cat_sofa.jsx'
import { European_girl_baby_anim_gangnam } from './jsx/European_girl_baby_anim_gangnam.jsx'
import { European_man_young_anim_accordion } from './jsx/European_man_young_anim_accordion.jsx'
import { Indian_girl_baby_anim_accordion } from './jsx/Indian_girl_baby_anim_accordion.jsx'
import { Latin_boy_baby_anim_Borat } from './jsx/Latin_boy_baby_anim_Borat.jsx'
import { Latin_woman_middle_anim_gangnam } from './jsx/Latin_woman_middle_anim_gangnam.jsx'
import { Latin_woman_old_anim_accordion } from './jsx/Latin_woman_old_anim_accordion.jsx'
import { Latin_woman_young_anim_gangnam } from './jsx/Latin_woman_young_anim_gangnam.jsx'
import { _anim_accordion } from './jsx/_anim_accordion.jsx'
import { _anim_borat } from './jsx/_anim_borat.jsx'
import { _anim_gangnam } from './jsx/_anim_gangnam.jsx'
// 
import { African_girl_baby_anim_bodilymaneuvers } from './other_jsx/African_girl_baby_anim_bodilymaneuvers.jsx'
import { African_woman_young_anim_hopak } from './other_jsx/African_woman_young_anim_hopak.jsx'
import { Arabic_woman_old_anim_chickendance } from './other_jsx/Arabic_woman_old_anim_chickendance.jsx'
import { Arab_boy_baby_anim_other } from './other_jsx/Arab_boy_baby_anim_other.jsx'
import { Arab_man_middle_anim_bodilymaneuvers } from './other_jsx/Arab_man_middle_anim_bodilymaneuvers.jsx'
import { Arab_woman_teenager_anim_hopak } from './other_jsx/Arab_woman_teenager_anim_hopak.jsx'
import { Asian_girl_baby_anim_hopak } from './other_jsx/Asian_girl_baby_anim_hopak.jsx'
import { European_man_middle_anim_chickendance } from './other_jsx/European_man_middle_anim_chickendance.jsx'
import { European_man_teenager_anim_hopak_gopnik } from './other_jsx/European_man_teenager_anim_hopak_gopnik.jsx'
import { European_woman_teenager_anim_other } from './other_jsx/European_woman_teenager_anim_other.jsx'
import { Indian_boy_baby_anim_chickendance } from './other_jsx/Indian_boy_baby_anim_chickendance.jsx'
import { Indian_man_middle_anim_hopak } from './other_jsx/Indian_man_middle_anim_hopak.jsx'
import { Indian_man_old_anim_hopak } from './other_jsx/Indian_man_old_anim_hopak.jsx'
import { Latin_girl_baby_anim_other } from './other_jsx/Latin_girl_baby_anim_other.jsx'

import { Link } from 'react-router-dom';

import { useControls, button, folder } from 'leva'

import { Text, Html } from '@react-three/drei';
import { TextureLoader , MirroredRepeatWrapping} from 'three';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import StyledButton from './StyledButton';

import Button from "@mui/material/Button";
import {Container, Stack} from "@mui/material";


import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook , faCircle, faCalendarTimes, faImage } from '@fortawesome/free-solid-svg-icons'; // Replace 'faIconName' with the icon you want to use

import * as gen from "./external";


import './stylish.css';


const ffmpeg = createFFmpeg({ log: true });

export function ObjectDetector(props){
       
        const fileInputRef = useRef();
        const imageRef = useRef();
        const [my_image_data, set_img_data] = useState(null);
        const [predictions, set_predictions] = useState([]);
        const [results,set_results] = useState([]);
        const [loading, set_loading] = useState(false);
        const [rec_loading, set_rec_loading] = useState(false);
        const [myModel, setModel] = useState(null);

        // ****************************** // 

        const empty = !predictions || predictions.length === 0;
        const empty2 = !results || results.length === 0;

        const openFilePicker =() =>{
            if(fileInputRef.current) fileInputRef.current.click();
        }
        
        const ref = useRef();
        


        const [ready, setReady] = useState(false);
        const [video, setVideo] = useState([]);
        const [gif, setGif] = useState();




        const [my_url,seturl] = useState('');

        const load = async () => {
            await ffmpeg.load();
            setReady(true);
        };
          
        function normalizePredictions(predictions,imgSize){
            if(!predictions || !imgSize || !imageRef) return predictions || [];
            return predictions.map((prediction) => {
                const {bbox} = prediction;
                const oldX = bbox[0];
                const oldY = bbox[1];
                const oldWidth = bbox[2];
                const oldHeight = bbox[3];

                const imgWidth = imageRef.current.width;
                const imgHeight = imageRef.current.height;
                const x = (oldX * imgWidth) / imgSize.width;
                const y = (oldY * imgHeight) / imgSize.height;
                const width = (oldWidth * imgWidth) / imgSize.width;
                const height = (oldHeight* imgHeight) / imgSize.height;

                return { ...prediction, bbox:[x,y,width,height] };
            })
        }

   

        async function detectObjectsOnImage(imageElem, imgSize){
            const model = await cocoSsd.load({});
            const predictions = await model.detect(imageElem,6);
            const normalizedPredictions = normalizePredictions(predictions,imgSize);
            set_predictions(normalizedPredictions);
            console.log("Predictions ", predictions);
        }


        
        const MyList = () => {
            if(!empty){
                const listItems = predictions.map(prediction  => <li>{prediction}</li>);
            }
            return ( <ul>listItems</ul> )
        }

        const detectWithNet = async(input_image) => {
            const model = await network.load();
            const results = await model.classify(input_image);    
            set_results(results);
            console.log(results)
        }
        

        const readImage = (file) =>{
            return new Promise((rs,rj) => {
                const filereader = new FileReader();
                filereader.onload = () => rs(filereader.result);
                filereader.onerror = () => rj(filereader.error);
                filereader.readAsDataURL(file);
            })
        }
        
        const onSelectImage = async(e) => {
            set_predictions([]);
            set_loading(true)

            setSittingNums(generate_random_number_array(10))
            setlaughingNums(generate_random_number_array(6))
            setSelfieNums(generate_random_number_array(6))
            setCrimeNums(generate_random_number_array(20))
            setDrinkingNums(generate_random_number_array(8))
            setFractureNums(generate_random_number_array(6))
            setStandpetNums(generate_random_number_array(6))

            const file = e.target.files[0];
            const my_image_data = await readImage(file);

            set_img_data(my_image_data);
            const imageElem = document.createElement("img")
            imageElem.src = my_image_data;
        
            imageElem.onload = async() => {
                const imgSize = {
                    width:imageElem.width/20,
                    height:imageElem.height/20
                };
                await detectObjectsOnImage(imageElem, imgSize);
                await detectWithNet(imageElem);
                set_loading(false);
            };
        };

        const testImport = async () => {
          try {
            const { Model } = await import('./jsx/Asian_man_old_anim_gangnam.jsx');
            console.log('Import successful:', Model);
          } catch (error) {
            console.error('Import failed:', error);
          }
        };
        
        // Call the test function
        testImport();
      function Scene(){
        const gl = useThree((state) => state.gl)
        
      }

      function Giffer(){
        const gl = useThree((state) => state.gl)
        useControls({
          gif_capture: button(() => {
            const link = document.createElement('a')
            link.setAttribute('download', 'canvas.gif')
            link.setAttribute('href', gl.domElement.toDataURL('image/gif').replace('image/gif', 'image/octet-stream'))
            link.click()
          })
        })
      }

      const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      function generateString(length) {
          let result = ' ';
          const charactersLength = characters.length;
          for ( let i = 0; i < length; i++ ) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
          }
      
          return result;
      }

 // Your recording logic

const [actions, setActions] = useState(null)

const [rec, setRec] = useState(false)

const startRecording = useCallback(() => {
  recorder.createStream(ref.current);
  console.log("Record going ")
  setRec(true)
  recorder.start();
  //setTimeout(()=> stopRecording(),10000)
}, [ref])

const stopRecording = useCallback(() => {
  recorder.stop();
  setRec(false)
  const file = recorder.save();
  // Do something with the file
  console.log("Record stopped")

  setVideo(file)
            //seturl(file)
            console.log("VIDEO", video)
            // convert to gif 
            const simple = generateString(8) + ".webm"
            saveAs(file, simple)
            // ***
            convertToGif(generateString(8) + ".webm",file)
}, [])



        const [ffmpeg_loaded, ffmpeg_setloaded] = useState(false)

          const convertToGif = async (name,my_video) => {
            if(!ffmpeg.isLoaded()){
              load();
            }
            
            //await ffmpeg.load()
            if(ffmpeg.isLoaded()){
              ffmpeg_setloaded(true)
            }

            console.log("Is ffmpeg loaded??", ffmpeg.isLoaded())
              await ffmpeg.FS('writeFile', name, await fetchFile(my_video));
              await ffmpeg.run('-i', name, '-t', '2.5', '-ss', '2.0', '-f', 'gif', 'output.gif');
              console.log("**********************************");
              const data =  ffmpeg.FS('readFile', 'output.gif');
              const url = URL.createObjectURL(new Blob([data.buffer], { type: 'image/gif' }),);
              setTimeout(()=>  saveAs(url, generateString(8)+".gif"),3000)
             
              //createImage(url);
              seturl(url)
            };
            
            // declare the data fetching function
            
          const ShowUrl = () => {
            if(my_url){
              return(
                <>
                <a href = {my_url} download>Click here to download recording!</a>
                <img src={my_url} width = "400" height = "600"/>
                </>
              )
            }
          }

          
      const CharacterCombo = () => {
        return (
          <>
          <RandomPersonage position={[1,0,2]} indexation={sittingNums[0]} />
          <RandomPersonage position={[-1,0,2]} indexation={sittingNums[0]} />

          <RandomLaugh position = {[-3.5,0,2]} indexation = {laughingNums[0]} />
          <RandomLaugh position = {[0.5,0,2.5]} indexation = {laughingNums[1]}/>
        
          <RandomSelfie position = {[2.5, 0, -3.5]} indexation = {selfieNums[2]}  />

          <RandomCriminal position = {[0,0,0]} indexation={crimeNums[1]} />

          </>
        )
       }


       const CharacterCombo2 = ({ 
        yPositionPersonage1 = 0, 
        yPositionPersonage2 = 0, 
        yPositionLaugh1 = 0, 
        yPositionLaugh2 = 0, 
        yPositionSelfie = 0, 
        yPositionCriminal = 0 
      }) => {
        return (
          <>
            <RandomPersonage position={[1, yPositionPersonage1, 2]} indexation={sittingNums[0]} />
            <RandomPersonage position={[-1, yPositionPersonage2, 2]} indexation={sittingNums[0]} />
      
            <RandomLaugh position={[-3.5, yPositionLaugh1, 2]} indexation={laughingNums[0]} />
            <RandomLaugh position={[0.5, yPositionLaugh2, 2.5]} indexation={laughingNums[1]} />
          
            <RandomSelfie position={[2.5, yPositionSelfie, -3.5]} indexation={selfieNums[2]} />
      
            <RandomCriminal position={[0, yPositionCriminal, 0]} indexation={crimeNums[1]} />
          </>
        );
      };

      const CharacterCombo3 = () => {
        return (
          <>
          <RandomPersonage position={[-2, 0, 0]} indexation={sittingNums[0]} />
          <RandomPersonage position={[0, 0, 0]} indexation={sittingNums[1]} />
          <RandomDrinking position={[2, 0, 0]} indexation={drinkingNums[0]} />
          <RandomDrinking position={[2, 0, 2]} indexation={drinkingNums[1]} />
          <RandomDrinking position={[0, 0, -3]} indexation={drinkingNums[2]} />
          <RandomDrinking position={[-2, 0, 2]} indexation={drinkingNums[3]} />
          <RandomLaugh position={[3.5, 0, 2]} indexation={laughingNums[0]} />
          <RandomLaugh position={[0.5, 0, -2.5]} indexation={laughingNums[1]} />
          <RandomLaugh position={[3.5, 0, 3]} indexation={laughingNums[2]} />
          <RandomLaugh position={[-2, 0, 4]} indexation={laughingNums[3]} />
          <RandomSelfie position={[-2.5, 0, 4.5]} indexation={selfieNums[0]} />
          <RandomSelfie position={[-1.5, 0, -4.5]} indexation={selfieNums[1]} />
          <RandomSelfie position={[2.5, 0, -3.5]} indexation={selfieNums[2]} />
          </>
        )
       }
          
       const CharacterCombo4 = () => {
        return (
          <>
          <RandomPersonage position={[-2, 0, 0]} indexation={sittingNums[0]} />
         
          <RandomDrinking position={[2, 0, 0]} indexation={drinkingNums[0]} />
          <RandomDrinking position={[2, 0, 2]} indexation={drinkingNums[1]} />
          <RandomFracture position={[0, 0, -3]} indexation={fractureNums[0]} />
          <RandomFracture position={[-2, 0, 2]} indexation={fractureNums[1]} />
          <RandomLaugh position={[3.5, 0, 2]} indexation={laughingNums[0]} />
          <RandomLaugh position={[0.5, 0, -2.5]} indexation={laughingNums[1]} />
          <RandomLaugh position={[3.5, 0, 3]} indexation={laughingNums[2]} />
          <RandomFracture position={[-1, 0, 4.5]} indexation={fractureNums[2]} />
          <RandomSelfie position={[-2.5, 0, 4.5]} indexation={selfieNums[0]} />
          <RandomSelfie position={[-1.5, 0, -4.5]} indexation={selfieNums[1]} />
          <RandomStandingPet position={[2.5, 0, -3.5]} indexation={standpetNums[0]} />
      
          </>
        )
       }
          const AnimCombo= () => {
            return (
              <>
          <Cat_Pose_cofee position={[0.7,0,0]} />

          <Cat_sofa />

          <European_girl_baby_anim_gangnam position={[-0.5,0,-0.2]}/>
          <European_man_young_anim_accordion position = {[-2,0,0]}/>

          <Indian_girl_baby_anim_accordion position={[2.5,0,0]}/>

          < _anim_gangnam />

              </>
            )
          }
          const randompeople = ["European_boy_baby_sitting.glb", 
          "European_girl_baby_sitting.glb", "European_man_middle_sitting.glb","European_man_old_sitting.glb","European_man_teenager_sitting.glb",
          "European_man_young_sitting.glb","European_woman_middle_sitting.glb","European_woman_old_sitting.glb","European_woman_teenager_sitting.glb","European_woman_young_sitting.glb"]

      
     
          const RandomPersonage = (props) => {


            //const [randomNumber] = useState(() => Math.floor(Math.random() * 10) + 1);
            //let rn = Math.floor(Math.random() * 9) + 1;
            
            let mod = randompeople[props.indexation];

           
            const gltf = useLoader(GLTFLoader, `./static_people/${mod}`);
            return (
                      <>
                        <primitive object={gltf.scene} scale={1.4} position={props.position} />
                      </>
                   );
            
        }
        

        const cups_drinks = ["African_woman_teenager_cup.glb", "Arab_woman_young_bottle.glb", "Asian_man_old_wineglass.glb", 
        "European_man_young_bottle.glb", "European_woman_middle_cup.glb", "Indian_boy_baby_cup.glb", "Latin_man_middle_bottle.glb", "Latin_man_teenager_bottle.glb"]

      
        const RandomDrinking = (props) => {

          let mod = cups_drinks[props.indexation];
          const gltf = useLoader(GLTFLoader, `./Cups_and_drinks/${mod}`);
          return (
                    <>
                      <primitive object={gltf.scene} scale={1.4} position={props.position} />
                    </>
                 );
          
         }

      const laughing = ["African_woman_old_laugh.glb", "Arab_man_teenager_laugh.glb", 
    "Asian_man_middle_laugh.glb", "European_girl_baby_laugh.glb", 
  "Indian_woman_young_laugh.glb", "Latin_woman_middle_laugh.glb"]
         const RandomLaugh = (props) => {
          let mod = laughing[props.indexation];

          
          const gltf = useLoader(GLTFLoader, `./Laugh/${mod}`);
          return (
                    <>
                      <primitive object={gltf.scene} scale={1.4} position={props.position} />
                    </>
                 );
          
      }

      const selfie = ["African_man_teenager_selfie.glb", "Arab_man_young_selfie.glb", 
    "Asian_woman_middle_selfie.glb", "European_boy_baby_selfie.glb", "Indian_girl_baby_selfie.glb",
  "Indian_woman_old_selfie.glb"]

      const RandomSelfie = (props) => {
        let mod = selfie[props.indexation];
        const gltf = useLoader(GLTFLoader, `./Selfie/${mod}`);
        return (
                  <>
                    <primitive object={gltf.scene} scale={1.4} position={props.position} />
                  </>
               );
        
    }

    const criminals = ["African_boy_baby_crim.glb",
  "African_man_middle.glb", "African_woman_middle_crim.glb",
"African_woman_young_crim.glb", "Arab_girl_baby_crim.glb",
"Arab_man_middle_crim.glb","Arab_woman_middle_crim.glb", "Asian_man_middle_crim.glb",
"Asian_man_teenager_crim.glb", "Asian_woman_old_crim.glb", "European_boy_baby_crim.glb", 
"European_man_old_crim.glb", "European_man_young_crim.glb", "European_woman_young_crim.glb", 
"Indian_man_old_crim.glb", "Indian_woman_teenager_crim.glb",
"Latin_girl_baby_crim.glb", "Latin_man_teenager_crim.glb", "Latin_man_young_crim.glb",
"Latin_woman_teenager_crim.glb"]
    
const RandomCriminal = (props) => {      
  let mod = criminals[props.indexation];
  const gltf = useLoader(GLTFLoader, `./People_crim/${mod}`);
  return (
            <>
              <primitive object={gltf.scene} scale={1.6} position={props.position} />
            </>
         );

  }

const standing_with_pets = ["African_man_young_pet.glb", "Arab_woman_middle_pet.glb", "Asian_boy_baby_pet.glb", "European_woman_young_pet.glb", "Indian_man_teenager_pet.glb", "Latin_woman_old_pet.glb"]

const RandomStandingPet = (props) =>{

    let mod = standing_with_pets[props.indexation];
  const gltf = useLoader(GLTFLoader, `./Standing_with_pets/${mod}`);
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

const fractures = ["African_woman_middle_fracture.glb", "Arab_woman_old_fracture.glb", "Asian_man_young_fracture.glb", "European_woman_teenager_fracture.glb", "Indian_man_old_fracture.glb", "Latin_girl_baby_fracture.glb"]
 
const RandomFracture = (props) =>{

let mod = fractures[props.indexation];
const gltf = useLoader(GLTFLoader, `./Fracture/${mod}`);
return (
          <>
            <primitive object={gltf.scene} scale={1.4} position={props.position} />
          </>
       );
}



    
        function generate_random_number_array(at_len){
          var randomNumbers = [];
  
              while (randomNumbers.length < 4) {
                  var randomNumber = Math.floor(Math.random() * at_len);
  
                  // Check if the randomNumber is not already in the array
                  if (randomNumbers.indexOf(randomNumber) === -1) {
                      randomNumbers.push(randomNumber);
                  }
              }
              return randomNumbers;
         }
        
          const [sittingNums, setSittingNums] = useState(generate_random_number_array(10))
          const [drinkingNums, setDrinkingNums] = useState(generate_random_number_array(8))
          const [laughingNums, setlaughingNums] = useState(generate_random_number_array(6))
          const [crimeNums, setCrimeNums] = useState(generate_random_number_array(20))
          const [selfieNums, setSelfieNums] = useState(generate_random_number_array(6))

          // Adjust nums as well 
          const [standpetNums, setStandpetNums] = useState(generate_random_number_array(5))
          const [fractureNums, setFractureNums] = useState(generate_random_number_array(5))

          const [animated, setAnimated] = useState(false);

        

          // 
       const [rnc, setRnc] = useState(Math.floor(Math.random() * 9) + 1);
       const componentMap = {
        "Brasil": ["ball","soccer ball", "swimming trunks, bathing trunks", "boathouse", "motor scooter, scooter", "maillot, tank suit", "surfboard"],
        "Rome": ["pizza","pizza, pizza pie", "patio, terrace", "espresso", "coffee_mug"],
        "NewYork": ["hot dog", "submarine, pigboat, sub, U-boat", "space shuttle", "cowboy boot"],
        "Moscow": ["revolver, six-gun, six-shooter", "missile", "slot, one-armed bandit", "assault rifle, assault gun", "ambulance", "cannon"],
        "Iceland": ["penguin", "volcano", "geyser", "horse"],
        "Kenya": ["monkey", "cat", "banana", "baboon", "Indian Elephant, Elephas maximus", "tiger, Panthera tigris"],
        "North_Korea": ["cow", "chicken"],
        "Baghdad": ["Arabian camel, dromedary, Camelus dromedarius"],
        "China": ["giant panda, panda, panda bear, coon bear, Ailuropoda melanoleuca", "screw", "bowl", "hot pot, hotpot", "cellular telephone, cellular phone, cellphone, cell, mobile phone"],
        "Dubai": ["mosque", "obelisk", "crane", "Arabian camel, dromedary, Camelus dromedarius", "window shade"],
        "Egypt": ["scuba diver", "lionfish", "sandbar, sand bar"],
        "Japan": ["comic book", "kimono", "mask", "dock, dockage, docking facility"],
        "Kyiv": ["train", "potted plant", "groom, bridegroom", "trolleybus, trolley coach, trackless trolley", "soup bowl", "harvester, reaper"],
        "Mexico": ["pirate, pirate ship"],
        "Norway": ["valley, vale", "alp", "ski mask", "pier", "catamaran", "trimaran", "speedboat", "bobsled, bobsleigh, bob"],
        "Seoul": ["sandwich", "monastery"],
        "Spain": ["wine", "wine glass", "red wine"],
        "Sweden": ["hatchet", "coral reef", "fish", "shield, buckler", "moving van", "broccoli"],
        "Sydney": ["kangaroo", "liner, ocean liner", "sheep", "canoe", "wallaby, brush kangaroo"],
        "Yerevan": ["volcano", "carbonara", "wok", "library", "altar", "fountain"],
        "Hollywood": ["cliff, drop, drop-off"],
        "Agra": ["cow", "ox", "church, church building", "guenon, guenon monkey"],
        "Venice": ["boat", "sax, saxophone", "loggerhead, loggerhead turtle, Dermochelys coriacea"],
        "London": ["dock, dockage, docking facility"],
        "Amsterdam": ["bicycle", "bicycle-built-for-two, tandem bicycle, tandem", "tobacco shop, tobacconist shop, tobacconist", "slot, one-armed bandit"],
        "Airport": ["passenger car, coach, carriage", "airplane", "airship, dirigible"],
        "BasketballCourt": ["basketball"],
        "Beach": ["cliff, drop, drop-off", "fountain", "umbrella", "lakeside, lakeshore"],
        "Jungle": ["viaduct", "green snake, grass snake", "valley, vale", "dam, dike, dyke"],
        "Lunapark": ["carousel, carrousel, merry-go-round, roundabout, whirligig", "toyshop", "confectionery, confectionary, candy store", "pirate, pirate ship"],
        "RailwayStation": ["train", "electric locomotive"],
        "Spaceport": ["planetarium", "radio telescope, radio reflector"],
        "Stadium2": ["ballplayer, baseball player", "football helmet", "chair"],
        "StreetMarket": ["shoe shop, shoe-shop, shoe store", "bookshop, bookstore, bookstall", "grocery store, grocery, food market, market"],
        "StreetRain": ["bird", "unicycle, monocycle", "jinrikisha, ricksha, rickshaw", "potted plant"],
        "UfoBase": ["tarantula", "triceratops"],
        "Office": ["library", "laptop", "sliding door"],
        "Alcoholics": ["beer bottle", "academic gown, academic robe, judge's robe"],
        "Cafe": ["cup", "cake"],
        "Cinema": ["wig", "microphone, mike", "bulletproof vest"],
        "GarbageFactory": ["garbage truck, dustcart", "forklift", "ashcan, trash can, garbage can, wastebin, ash bin, ash-bin, ashbin, dustbin, trash barrel, trash bin"],
        "HappyBirthday": ["bathing cap, swimming cap", "dining table", "candle, taper, wax light"],
        "Hospital2": ["lab coat, laboratory coat", "ambulance", "fire engine, fire truck"],
        "Kitchen": ["restaurant, eating house, eating place, eatery", "Dutch oven", "red wine", "bowl", "frying pan, frypan, skillet", "wok"],
        "LivingRoom": ["Christmas stocking", "rocking chair, rocker", "dishwasher, dish washer, dishwashing machine"],
        "Prison": ["syringe", "pill bottle", "German shepherd, German shepherd dog, German police dog, alsatian"],
        "Theatre": ["theater curtain, theatre curtain", "palace", "cinema, movie theater, movie theatre, movie house, picture palace", "velvet"],
        "Autumn": ["bear", "fox squirrel, eastern fox squirrel, Sciurus niger", "robin, American robin, Turdus migratorius", "grey fox, gray fox, Urocyon cinereoargenteus", "mushroom"],
        "Winter": ["snowplow, snowplough", "snowboard", "ski"],
        "Spring": ["vase", "bee", "pot, flowerpot", "daisy"],
        "Summer": ["beacon, lighthouse, beacon light, pharos", "breakwater, groin, groyne, mole, bulwark, seawall, jetty", "tiger shark, Galeocerdo cuvieri", "kite"],
        "DefaultPerson":["person"]
      };


    const components = {
        "Brasil": <><gen.Brasil /><CharacterCombo />  < _anim_accordion scale={1.4} position={[4,0,0]}  /> 
         < _anim_borat scale={1.4} position={[-3,0,0]} />  </>,
        "Rome": <><gen.Rome position={[0,0,3]} /><CharacterCombo /> < _anim_gangnam scale={1.4} position={[-2,0,0]}/>  </>,
        "NewYork": <><gen.NewYourk position={[0,-0.3,0]}/><CharacterCombo /> < _anim_gangnam scale={1.4} position={[-2,0,0]}/>
         < African_man_young_anim_gangnam scale={1.4} position={[2,0,0]}/> </>,
        "Moscow": <><gen.Moscow /><CharacterCombo /> 
        < European_man_teenager_anim_hopak_gopnik position={[3,0,-2]} scale={1.4} /> 
        <European_man_middle_anim_chickendance position = {[-3,0,-1]} scale={1.4}/> 
    
        <_anim_borat scale={1.8} position={[2,0,0]} /> </>,
        "Iceland": (
          <>
            <gen.Animals_penguin />
            <gen.Iceland position={[0,0.7,0]} /><CharacterCombo />

            <Cat_sofa scale={1.4} position={[4,0,0]}/>
            < Cat_Pose_cofee scale={1.4} position={[-2,0,0]}/>
          </>
        ),
        "Kenya": <><gen.Kenya /><CharacterCombo />
            <Cat_Pose_anim_box scale={1.4} position={[4,0,0]}/>
            < European_man_young_anim_accordion scale={1.4} position={[-3,0,-1]}/>
        </>,
        "North_Korea": <><gen.North_Korea />
            <Latin_boy_baby_anim_Borat scale={1.4} position={[4,0,0]}/>
            < Cat_Pose_anim_baguette scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Baghdad": <><gen.Baghdad />
          <Latin_boy_baby_anim_Borat scale={1.4} position={[4,0,0]}/>
          < Cat_Pose_anim_baguette scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "China": <><gen.China />
          <European_girl_baby_anim_gangnam scale={1.4} position={[4,0,0]}/>
          < Cat_Pose_anim_drunk_master scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Dubai": <><gen.Dubai />
              <Latin_woman_young_anim_gangnam scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Egypt": <><gen.Egypt position={[0,-0.2,0]}/>
              <Asian_man_old_anim_gangnam scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo />  </>,
        "Japan": <><gen.Japan position={[12,0,0]} />
              <Asian_man_middle_anim_Borat scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_breacdance2 scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Kyiv": <><gen.Kyiv />
              <African_man_young_anim_gangnam scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_breacdance scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Mexico": <><gen.Mexico position={[0,-0.65,0]}/>
              <European_girl_baby_anim_gangnam scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_drunk_master scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Norway": <><gen.Norway />
              <Asian_man_middle_anim_Borat scale={1.4} position={[4,0,0]}/>
              < Cat_sofa scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo2 yPositionLaugh1={0.2} yPositionLaugh2={0.1}/></>,
        "Seoul": <><gen.Seoul />
              <European_man_young_anim_accordion scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_breacdance2 scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Spain": <><gen.Spain />
              <Indian_girl_baby_anim_accordion scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_box scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Sweden": <><gen.Sweden />
              <European_man_young_anim_accordion scale={1.4} position={[4,0,0]}/>
              < Cat_sofa scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Sydney": <><gen.Sydney />
              <European_girl_baby_anim_gangnam scale={1.4} position={[4,0,0]}/>
              < Cat_sofa scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Yerevan": <><gen.Yerevan />
              <Latin_woman_middle_anim_gangnam scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_box scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Hollywood": <><gen.Hollywood />
              <African_man_young_anim_gangnam scale={1.4} position={[4,0,0]}/>
              < Arab_man_middle_anim_bodilymaneuvers scale={1.4} position={[-2,0,-3]}/>
              < Cat_Pose_anim_baguette scale={1.4} position={[-8,0,-3]}/>
              <CharacterCombo4 />
        </>,
        "Agra": <><gen.Agra />
              <European_girl_baby_anim_gangnam scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_breacdance scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Venice": <><gen.Venice position={[7,-3,0]} />
              <Latin_woman_old_anim_accordion scale={1.4} position={[2,0,-5]}/>
              < Cat_Pose_cofee scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "London": <><gen.London position={[8,0,0]}/>
              <_anim_gangnam scale={1.4} position={[2,0,0]}/>
              < Cat_Pose_cofee scale={1.4} position={[-2,0,-3]}/>
              <European_woman_teenager_anim_other scale={1.4} position={[3,0,-2]}/>

        <CharacterCombo /></>,
        "Amsterdam": <><gen.Amsterdam />
              <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,0]}/>
              < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
              <European_woman_teenager_anim_other scale={1.4} position={[1,0,1]}/>

        <CharacterCombo /></>,
        "Airport": (
          <>
            <gen.Airport />
            <RandomPersonage position={[-2, 0, 0]} indexation={sittingNums[0]} />
            <RandomPersonage position={[0, 0, 0]} indexation={sittingNums[1]} />
            <RandomPersonage position={[2, 0, 1]} indexation={sittingNums[2]} />
            <RandomCriminal position={[2, 0, 2]} indexation={crimeNums[0]} />
            <RandomCriminal position={[0, 0, 2]} indexation={crimeNums[1]} />
            <RandomCriminal position={[-2, 0, 2]} indexation={crimeNums[2]} />
            <RandomDrinking position={[-1.5, 0, 1.25]} indexation={drinkingNums[0]} />
            <RandomPersonage position={[-1.2, 0, 4]} indexation={drinkingNums[1]} />
            <Cat_Pose_anim_box scale={1.4} position={[4,0,0]}/>
            < Asian_man_middle_anim_Borat scale={1.4} position={[-2,0,-3]}/>
          </>
        ),
        "BasketballCourt": (
          <>
            <gen.BasketballCourt />
            <RandomPersonage position={[-2, 0, 0]} indexation={sittingNums[0]} />
            <RandomPersonage position={[0, 0, 0]} indexation={sittingNums[1]} />
            <RandomDrinking position={[2, 0, 0]} indexation={drinkingNums[0]} />
            <RandomDrinking position={[2, 0, 2]} indexation={drinkingNums[1]} />
            <RandomDrinking position={[0, 0, -3]} indexation={drinkingNums[2]} />
            <RandomDrinking position={[-2, 0, 2]} indexation={drinkingNums[3]} />
            <RandomLaugh position={[3.5, 0, 2]} indexation={laughingNums[0]} />
            <RandomLaugh position={[0.5, 0, -2.5]} indexation={laughingNums[1]} />
            <RandomLaugh position={[3.5, 0, 3]} indexation={laughingNums[2]} />
            <RandomLaugh position={[-2, 0, 4]} indexation={laughingNums[3]} />
            <RandomSelfie position={[-2.5, 0, 4.5]} indexation={selfieNums[0]} />
            <RandomSelfie position={[-1.5, 0, -4.5]} indexation={selfieNums[1]} />
            <RandomSelfie position={[2.5, 0, -3.5]} indexation={selfieNums[2]} />

            < Indian_girl_baby_anim_accordion scale={1.4} position={[4,0,0]}/>
            < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
          </>
        ),
        "Beach": <><gen.Beach />
         <Latin_boy_baby_anim_Borat scale={1.4} position={[4,0.3,0]}/>
         <Arab_man_middle_anim_bodilymaneuvers scale={1.4} position={[2,0.3,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Jungle": <><gen.Jungle />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Lunapark": <><gen.Lunapark />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,-3]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo4 /> <Indian_boy_baby_anim_chickendance position={[0,0,1]} scale={1.7} />  </>,
        "RailwayStation": <><gen.RailwayStation />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
         < European_man_middle_anim_chickendance scale={1.4} position={[-4,0,-3]}/>
        <CharacterCombo /></>,
        "Spaceport": <><gen.Spaceport />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,0]}/>
         <European_woman_teenager_anim_other scale={1.4} position={[2,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Stadium2": <><gen.Stadium2 />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
         <European_woman_teenager_anim_other scale={1.4} position={[2,0,0]}/>

        <CharacterCombo /></>,
        "StreetMarket": <><gen.StreetMarket />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
         <European_woman_teenager_anim_other scale={1.4} position={[2,0,0]}/>

        <CharacterCombo4 /></>,
        "StreetRain": <><gen.StreetRain />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,0]}/>
         
        <CharacterCombo /></>,
        "UfoBase": <><gen.UfoBase position={[0,-1.9,0]}/>
                 <European_woman_teenager_anim_other scale={1.4} position={[2,0,0]}/>

        <CharacterCombo /></>,
        // Beginning Interior spaces 

        "Office": (
          <>
         
            <gen.Office position={[0,0,2]} />

            <African_girl_baby_anim_bodilymaneuvers scale={1.4} position={[0,0,0]} />              
            <Asian_girl_baby_anim_hopak scale={1.4} position={[1,0,2]} />              
            < Cat_Pose_anim_dab_cycled scale={1.2} position={[-2,0,2]}/>

          </>
        ),
        "Alcoholics": <><gen.Alcoholics />
         <Arab_woman_teenager_anim_hopak scale={1.4} position={[4,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-1,0,-3]}/>
         <European_woman_teenager_anim_other scale={1.4} position={[-3,0,0]}/>
        <CharacterCombo /></>,
        
        "Cafe": <><gen.Cafe />
         <Arabic_woman_old_anim_chickendance scale={1.4} position={[2,0,-4]}/>
         <Arab_woman_middle_anim_accordion scale={1.4} position={[2,0,-1]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
         < Arab_boy_baby_anim_other scale={2.1} position={[-2.6,0,0]}/>
         <RandomLaugh position={[0, 0, 0]} indexation={laughingNums[0]} />
         <RandomDrinking position={[-5, 0, -2]} indexation={laughingNums[0]} />
        </>,
        "Cinema": (
          <>
            <gen.Cinema position={[0,-3,0]}/>
            <RandomCriminal position={[0, -3, -9]} indexation={crimeNums[0]} />
            <RandomCriminal position={[-5, -3, -9]} indexation={crimeNums[1]} />
            <RandomCriminal position={[-4, -3, -9]} indexation={crimeNums[2]} />
            <RandomCriminal position={[-3, -3, -9]} indexation={crimeNums[3]} />
            <Arab_man_young_anim_gangam scale={1.4} position={[4,-3,-9]}/>
            < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,-3,-9]}/>
          </>
        ),
        "GarbageFactory": <><gen.GarbageFactory />
         <Arab_man_young_anim_gangam scale={1.4} position={[2,0,-5]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-3,0,-3]}/>
        <CharacterCombo /></>,
        "HappyBirthday": <><gen.HappyBirthday />
         <Indian_man_middle_anim_hopak scale={1.4} position={[4,0,0]}/>
         <European_man_middle_anim_chickendance scale={1.4} position={[2,0,-1]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Hospital2": <><gen.Hospital2 position={[0,0,2]}/>
         <Arab_man_young_anim_gangam scale={1.4} position={[4,0,0]}/>
         <Latin_girl_baby_anim_other scale={1.8} position={[2,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
        <CharacterCombo /></>,
        "Kitchen": <><gen.Kitchen  position={[0,-0.3,2.8]} />
         <Asian_man_old_anim_gangnam scale={1.4} position={[1,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
         < European_man_teenager_anim_hopak_gopnik scale={1.4} position={[-4,0,-3]}/>
        <CharacterCombo /></>,

        // SOlve living room positions
        "LivingRoom": <><gen.LivingRoom />
         <Arabic_woman_old_anim_chickendance scale={1.4} position={[2,0,1]}/>
         < Cat_Pose_cofee scale={1.4} position={[-2,0,-1]}/>
        <CharacterCombo /></>,
        "Prison": <><gen.Prison position={[0,-1.3,0]}/>
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,-3]}/>
         < Indian_boy_baby_anim_chickendance scale={1.8} position={[2,0,0]}/>
        <CharacterCombo /></>,
        "Theatre": <><gen.Theatre position={[0,-0.2,0]}/>
         <Arab_woman_middle_anim_accordion scale={1.4} position={[6,0,-3]}/>
         < Arab_woman_teenager_anim_hopak scale={1.4} position={[-1,0,-1]}/>
        <CharacterCombo4 /></>,
        "Autumn": <><gen.Autumn />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[5,0,1]}/>
         <Asian_girl_baby_anim_hopak scale={1.4} position={[4,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,0]}/>
        <CharacterCombo /></>,
        "Winter": <><gen.Winter />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,1]}/>
         <Asian_girl_baby_anim_hopak scale={1.4} position={[4,0,0]}/>
         < Asian_girl_baby_anim_hopak scale={1.4} position={[-2,0,0]}/>
        <CharacterCombo /></>,
        "Spring": <><gen.Spring />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,1]}/>
         <Asian_girl_baby_anim_hopak scale={1.4} position={[4,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,0]}/>
        <CharacterCombo /></>,
        "Summer": <><gen.Summer />
         <Arab_woman_middle_anim_accordion scale={1.4} position={[4,0,1]}/>
         <Asian_girl_baby_anim_hopak scale={1.7} position={[2,0,0]}/>
         < Cat_Pose_anim_dab_cycled scale={1.4} position={[-2,0,0]}/>
        <CharacterCombo /></>,
        "DefaultPerson":<> <gen.North_Korea /> <Asian_girl_baby_anim_hopak scale={1.7}/>  </>
      };

      const combineResults = (results, predictions) => {
        // Extract classNames from the first array
        const classNames = results.map(result => result.className);
    
        // Extract classes from the second array
        const classes = predictions.map(prediction => prediction.class);
    
        // Combine both arrays
        const combinedResults = [...classNames, ...classes];
    
        return combinedResults;
    };
 /*
    const [combinedResults, setCombinedResults] = useState([]);

    useEffect(() => {
        // Combine the results whenever results or predictions change
        setCombinedResults(combineResults(results, predictions));
    }, [results, predictions]);
    */
        const Script2 = () => {

        
          const defaultComponent = (
            <>
              {/*<gen.UfoBase position={[0,-2,0]} /> */}
              <gen.North_Korea />
              < _anim_accordion scale={1.8} position={[4,0,0]} />
              <European_woman_teenager_anim_other scale={1.8} position={[-4,0,0]}/>

              <CharacterCombo2 />
            </>
          );


          
          for(let i= 0; i<results.length;i++){
            let D = results[i].className;
            //let D = combinedResults[i];
            for (const [key, values] of Object.entries(componentMap)) {
              if (values.includes(D)) {
                return components[key];
              }
            }
            return defaultComponent;
            // Open Places
          }


        }

        const Version = () => {
          return components["Alcoholics"]
        }
        
   

        useEffect(() => {
          const canvas = ref.current;
      
          const handleScreenshot = () => {
            const dataUrl = canvas.toDataURL();
      
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'screenshot.png';
            link.click();
          };
      
          // Attach the screenshot function to a global object for easy access
          window.takeScreenshot = handleScreenshot;
        }, []);
      
        const handleScreenshot = () => {
          window.takeScreenshot()
        };
              
    
        useEffect(() => {
          if(!ffmpeg.isLoaded()){
            load();
          }
          }, []);

          const theme = createMuiTheme();

            //                <button onClick={startRecording}>{ rec_loading ? "Record in progress" : "Record and save"} </button>

//   <Environment preset="sunset" background />


        const [userText, setUserText] = useState('');

        const handleInputChange = (e) => {
          setUserText(e.target.value);
        };

        const AlwaysFacingText = ({ children, ...props }) => {
          const ref = useRef();
          useFrame(({ camera }) => {
            ref.current.quaternion.copy(camera.quaternion);
          });
        
          return (
            <Text ref={ref} {...props}>
              {children}
            </Text>
          );
        };
        

        return (

            <gen.ObjectDetectorContainer>
              

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px' }}>
                <div>
                <gen.DetectorContainer>
                    {my_image_data && <gen.TargetImg src={my_image_data} ref={imageRef} />}
                    </gen.DetectorContainer>
                    </div>
                    
                    <div>
                    {/*<FontAwesomeIcon icon={faImage} className="icon" /> */}
                    {/*<h2>{ffmpeg_loaded ? "FFMPEG has been successfully loaded": "FFmpeg did not load properly"}</h2>*/}
                    <span>Select your picture and get a 3D scene out of it </span>

                    <gen.FileInput type="file" ref={fileInputRef} onChange={onSelectImage} />

                    <Stack spacing={3} direction="row">
                        <Button className="gray-button" onClick={openFilePicker}>
                        <FontAwesomeIcon icon={faImage} className="icon" />
                        {loading ? "Recognizing" : "Select Image"}
                        </Button>
                        
                        <Button className="gray-button" onClick={startRecording}>
                        <FontAwesomeIcon icon={faCircle} className={rec ? "red-rec" : "icon"}  />
                        {rec ? "Record in progress" : "Start Recording"}
                        </Button>

                        
                         <Button  className="gray-button" onClick={stopRecording}>Stop Recording</Button>

                        <Button  className="gray-button" onClick={handleScreenshot}>
                        <FontAwesomeIcon icon={faAddressBook} className="icon" /> Screenshot
                        </Button>


                    </Stack>


                        <ul>
                        {!empty && predictions.map((prediction,index) => (
                            <li key={index}> {prediction.class} </li> 
                        ))}

                        {!empty2 && results.map((result,index) => (
                            <li key={index}> {result.className} </li> 
                        ))} 
 
{/* 
     {!empty && combinedResults.map((pred, index) => (
                                        <li key={index}> {pred} </li> 
                        ))}    
                     

*/}            
                   
                  
                    
                        </ul>
                          
                        <input
                            type="text"
                            value={userText}
                            onChange={handleInputChange}
                            placeholder="Enter text"
                        /> <span>Your text inside scene</span>
                      
                </div>
            </div>

      
                <div style={{ width: "100vw", height: "70vh" }}>    
                    
            <Canvas ref={ref} gl={{ preserveDrawingBuffer: true }} >
                    <Suspense fallback={null}>
                    <Script2/>
                 
                 
                      {/* <Version /> */}
              
                    <Scene/>

                

            <AlwaysFacingText
                    position={[0, 3, 1]} // Adjust position as needed
                    fontSize={0.5}        // Adjust size as needed
                    color="purple"         // Adjust color as needed
                    outlineWidth={0.05}
                    outlineColor="black"
                  >
                    {userText}
            </AlwaysFacingText>


       
                    <OrbitControls />
                    <Environment files="./img/industrial_sunset_puresky_1k.hdr" background /> 
                  
                </Suspense>

                </Canvas>
              
                </div>

            </gen.ObjectDetectorContainer>
            
        )

}



