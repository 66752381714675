/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Asian_man_old_anim_gangnam.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Asian_man_old_anim_gangnam(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Asian_man_old_anim_gangnam-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions.ArmatureAction.play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rp_nathan_animated_003_walking" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="rp_nathan_animated_003_walking_CTRL">
            <group name="Armature">
              <primitive object={nodes.rp_nathan_animated_003_walking_root} />
              <group name="rp_nathan_animated_003_walking_geo">
                <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['rp_nathan_animated_003_mat.001']} skeleton={nodes.Mesh001.skeleton} />
                <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh001_1.skeleton} />
              </group>
              <skinnedMesh name="12979_rain_jacket__adult_v1_l2" geometry={nodes['12979_rain_jacket__adult_v1_l2'].geometry} material={materials.rain_jacket__adult} skeleton={nodes['12979_rain_jacket__adult_v1_l2'].skeleton} morphTargetDictionary={nodes['12979_rain_jacket__adult_v1_l2'].morphTargetDictionary} morphTargetInfluences={nodes['12979_rain_jacket__adult_v1_l2'].morphTargetInfluences} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Asian_man_old_anim_gangnam-transformed.glb')
