/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\Asian_girl_baby_anim_hopak.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Asian_girl_baby_anim_hopak(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/Asian_girl_baby_anim_hopak-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["ArmatureAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.Little_Bree} />
          <group name="Body_16169_Shape">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['Body.001']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Fingernails.001']} skeleton={nodes.Mesh001_1.skeleton} />
            <skinnedMesh name="Mesh001_2" geometry={nodes.Mesh001_2.geometry} material={materials['Fingernails.001']} skeleton={nodes.Mesh001_2.skeleton} />
            <skinnedMesh name="Mesh001_3" geometry={nodes.Mesh001_3.geometry} material={materials['Material.006']} skeleton={nodes.Mesh001_3.skeleton} />
          </group>
          <group name="EyeReflection_370_Shape">
            <skinnedMesh name="Mesh010" geometry={nodes.Mesh010.geometry} material={materials['Material.001']} skeleton={nodes.Mesh010.skeleton} />
            <skinnedMesh name="Mesh010_1" geometry={nodes.Mesh010_1.geometry} material={materials['Material.002']} skeleton={nodes.Mesh010_1.skeleton} />
          </group>
          <skinnedMesh name="f_trousers_01Mesh" geometry={nodes.f_trousers_01Mesh.geometry} material={materials['Material.008']} skeleton={nodes.f_trousers_01Mesh.skeleton} />
          <group name="Hair-female">
            <skinnedMesh name="Nurbs_-_Guia_de_caminho001" geometry={nodes['Nurbs_-_Guia_de_caminho001'].geometry} material={materials['Hair-female']} skeleton={nodes['Nurbs_-_Guia_de_caminho001'].skeleton} />
            <skinnedMesh name="Nurbs_-_Guia_de_caminho001_1" geometry={nodes['Nurbs_-_Guia_de_caminho001_1'].geometry} material={materials.cor} skeleton={nodes['Nurbs_-_Guia_de_caminho001_1'].skeleton} />
          </group>
          <skinnedMesh name="knitted_sweater_02Mesh" geometry={nodes.knitted_sweater_02Mesh.geometry} material={materials['Material.007']} skeleton={nodes.knitted_sweater_02Mesh.skeleton} />
          <group name="uploads_files_2981862_BunnySlippers">
            <skinnedMesh name="uploads_files_2981862_BunnySlippers_1" geometry={nodes.uploads_files_2981862_BunnySlippers_1.geometry} material={materials['Material.009']} skeleton={nodes.uploads_files_2981862_BunnySlippers_1.skeleton} />
            <skinnedMesh name="uploads_files_2981862_BunnySlippers_2" geometry={nodes.uploads_files_2981862_BunnySlippers_2.geometry} material={materials['Material.010']} skeleton={nodes.uploads_files_2981862_BunnySlippers_2.skeleton} />
          </group>
          <group name="uploads_files_2981862_BunnySlippers001">
            <skinnedMesh name="uploads_files_2981862_BunnySlippers002" geometry={nodes.uploads_files_2981862_BunnySlippers002.geometry} material={materials['Material.009']} skeleton={nodes.uploads_files_2981862_BunnySlippers002.skeleton} />
            <skinnedMesh name="uploads_files_2981862_BunnySlippers002_1" geometry={nodes.uploads_files_2981862_BunnySlippers002_1.geometry} material={materials['Material.010']} skeleton={nodes.uploads_files_2981862_BunnySlippers002_1.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/Asian_girl_baby_anim_hopak-transformed.glb')
