/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Latin_boy_baby_anim_Borat.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Latin_boy_baby_anim_Borat(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Latin_boy_baby_anim_Borat-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="Body454">
            <skinnedMesh name="Bodybaked454" geometry={nodes.Bodybaked454.geometry} material={materials['Material.006']} skeleton={nodes.Bodybaked454.skeleton} />
            <skinnedMesh name="Bodybaked454_1" geometry={nodes.Bodybaked454_1.geometry} material={materials['Material.008']} skeleton={nodes.Bodybaked454_1.skeleton} />
          </group>
          <group name="Body_16169_Shape">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.Body} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_1.skeleton} />
            <skinnedMesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_2.skeleton} />
            <skinnedMesh name="Mesh_3" geometry={nodes.Mesh_3.geometry} material={materials['Material.001']} skeleton={nodes.Mesh_3.skeleton} />
          </group>
          <skinnedMesh name="dungaree001" geometry={nodes.dungaree001.geometry} material={materials['Material.006']} skeleton={nodes.dungaree001.skeleton} />
          <skinnedMesh name="EyeReflection_370_Shape" geometry={nodes.EyeReflection_370_Shape.geometry} material={materials.Material} skeleton={nodes.EyeReflection_370_Shape.skeleton} />
          <skinnedMesh name="ms_shirt_women" geometry={nodes.ms_shirt_women.geometry} material={materials['Material.002']} skeleton={nodes.ms_shirt_women.skeleton} />
          <group name="Shoe001">
            <skinnedMesh name="Shoe001_1" geometry={nodes.Shoe001_1.geometry} material={materials['Mat.2']} skeleton={nodes.Shoe001_1.skeleton} />
            <skinnedMesh name="Shoe001_2" geometry={nodes.Shoe001_2.geometry} material={materials['Mat.1']} skeleton={nodes.Shoe001_2.skeleton} />
            <skinnedMesh name="Shoe001_3" geometry={nodes.Shoe001_3.geometry} material={materials['Mat.3']} skeleton={nodes.Shoe001_3.skeleton} />
            <skinnedMesh name="Shoe001_4" geometry={nodes.Shoe001_4.geometry} material={materials.Mat} skeleton={nodes.Shoe001_4.skeleton} />
          </group>
          <group name="Shoe002">
            <skinnedMesh name="Shoe002_1" geometry={nodes.Shoe002_1.geometry} material={materials['Mat.2']} skeleton={nodes.Shoe002_1.skeleton} />
            <skinnedMesh name="Shoe002_2" geometry={nodes.Shoe002_2.geometry} material={materials['Mat.1']} skeleton={nodes.Shoe002_2.skeleton} />
            <skinnedMesh name="Shoe002_3" geometry={nodes.Shoe002_3.geometry} material={materials['Mat.3']} skeleton={nodes.Shoe002_3.skeleton} />
            <skinnedMesh name="Shoe002_4" geometry={nodes.Shoe002_4.geometry} material={materials.Mat} skeleton={nodes.Shoe002_4.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Latin_boy_baby_anim_Borat-transformed.glb')
