/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\Arab_boy_baby_anim_other.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Arab_boy_baby_anim_other(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/Arab_boy_baby_anim_other-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="Body_16169_Shape">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.Body} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_1.skeleton} />
            <skinnedMesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_2.skeleton} />
            <skinnedMesh name="Mesh_3" geometry={nodes.Mesh_3.geometry} material={materials.Head} skeleton={nodes.Mesh_3.skeleton} />
          </group>
          <group name="Boot_L">
            <skinnedMesh name="Mesh007" geometry={nodes.Mesh007.geometry} material={materials.Clothing} skeleton={nodes.Mesh007.skeleton} />
            <skinnedMesh name="Mesh007_1" geometry={nodes.Mesh007_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh007_1.skeleton} />
          </group>
          <group name="Boot_L001">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials.Clothing} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh001_1.skeleton} />
          </group>
          <skinnedMesh name="EyeReflection_370_Shape" geometry={nodes.EyeReflection_370_Shape.geometry} material={materials.Material} skeleton={nodes.EyeReflection_370_Shape.skeleton} />
          <skinnedMesh name="Hoodie" geometry={nodes.Hoodie.geometry} material={materials.HoodiePantsShoes} skeleton={nodes.Hoodie.skeleton} />
          <skinnedMesh name="Leggings" geometry={nodes.Leggings.geometry} material={materials.OutfitDefault} skeleton={nodes.Leggings.skeleton} />
          <skinnedMesh name="TifaTankTop" geometry={nodes.TifaTankTop.geometry} material={materials.Tanktop} skeleton={nodes.TifaTankTop.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/Arab_boy_baby_anim_other-transformed.glb')
