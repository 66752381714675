/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Indian_girl_baby_anim_accordion.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Indian_girl_baby_anim_accordion(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Indian_girl_baby_anim_accordion-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["Animation"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="Body_16169_Shape">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.Body} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_1.skeleton} />
            <skinnedMesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials.Toenails} skeleton={nodes.Mesh_2.skeleton} />
            <skinnedMesh name="Mesh_3" geometry={nodes.Mesh_3.geometry} material={materials['Material.008']} skeleton={nodes.Mesh_3.skeleton} />
          </group>
          <skinnedMesh name="EyeReflection_370_Shape" geometry={nodes.EyeReflection_370_Shape.geometry} material={materials.Material} skeleton={nodes.EyeReflection_370_Shape.skeleton} />
          <group name="Kids-left001">
            <skinnedMesh name="Mesh003" geometry={nodes.Mesh003.geometry} material={materials['Material.008']} skeleton={nodes.Mesh003.skeleton} />
            <skinnedMesh name="Mesh003_1" geometry={nodes.Mesh003_1.geometry} material={materials['Material.003']} skeleton={nodes.Mesh003_1.skeleton} />
            <skinnedMesh name="Mesh003_2" geometry={nodes.Mesh003_2.geometry} material={materials['Material.002']} skeleton={nodes.Mesh003_2.skeleton} />
            <skinnedMesh name="Mesh003_3" geometry={nodes.Mesh003_3.geometry} material={materials['Material.003']} skeleton={nodes.Mesh003_3.skeleton} />
            <skinnedMesh name="Mesh003_4" geometry={nodes.Mesh003_4.geometry} material={materials['Material.004']} skeleton={nodes.Mesh003_4.skeleton} />
            <skinnedMesh name="Mesh003_5" geometry={nodes.Mesh003_5.geometry} material={materials['Material.004']} skeleton={nodes.Mesh003_5.skeleton} />
          </group>
          <group name="Kids-left311">
            <skinnedMesh name="Mesh317" geometry={nodes.Mesh317.geometry} material={materials['Material.008']} skeleton={nodes.Mesh317.skeleton} />
            <skinnedMesh name="Mesh317_1" geometry={nodes.Mesh317_1.geometry} material={materials['Material.003']} skeleton={nodes.Mesh317_1.skeleton} />
            <skinnedMesh name="Mesh317_2" geometry={nodes.Mesh317_2.geometry} material={materials['Material.002']} skeleton={nodes.Mesh317_2.skeleton} />
            <skinnedMesh name="Mesh317_3" geometry={nodes.Mesh317_3.geometry} material={materials['Material.003']} skeleton={nodes.Mesh317_3.skeleton} />
            <skinnedMesh name="Mesh317_4" geometry={nodes.Mesh317_4.geometry} material={materials['Material.002']} skeleton={nodes.Mesh317_4.skeleton} />
            <skinnedMesh name="Mesh317_5" geometry={nodes.Mesh317_5.geometry} material={materials['Material.004']} skeleton={nodes.Mesh317_5.skeleton} />
          </group>
          <skinnedMesh name="knitted_sweater_02Mesh" geometry={nodes.knitted_sweater_02Mesh.geometry} material={materials['Material.009']} skeleton={nodes.knitted_sweater_02Mesh.skeleton} />
          <group name="salopette">
            <skinnedMesh name="Mesh002" geometry={nodes.Mesh002.geometry} material={materials['Salopette.001']} skeleton={nodes.Mesh002.skeleton} />
            <skinnedMesh name="Mesh002_1" geometry={nodes.Mesh002_1.geometry} material={materials['Material.008']} skeleton={nodes.Mesh002_1.skeleton} />
            <skinnedMesh name="Mesh002_2" geometry={nodes.Mesh002_2.geometry} material={materials.catdetail} skeleton={nodes.Mesh002_2.skeleton} />
            <skinnedMesh name="Mesh002_3" geometry={nodes.Mesh002_3.geometry} material={materials.stch} skeleton={nodes.Mesh002_3.skeleton} />
            <skinnedMesh name="Mesh002_4" geometry={nodes.Mesh002_4.geometry} material={materials['Material.008']} skeleton={nodes.Mesh002_4.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Indian_girl_baby_anim_accordion-transformed.glb')
