/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\Indian_man_old_anim_hopak.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Indian_man_old_anim_hopak(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/Indian_man_old_anim_hopak-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["ReferenceAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="master" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="Reference">
            <primitive object={nodes.Hips} />
            <group name="frame">
              <skinnedMesh name="Circle" geometry={nodes.Circle.geometry} material={materials['Material.010']} skeleton={nodes.Circle.skeleton} />
              <skinnedMesh name="Circle_1" geometry={nodes.Circle_1.geometry} material={materials['Material.009']} skeleton={nodes.Circle_1.skeleton} />
            </group>
            <group name="H_DDS_HighRes">
              <skinnedMesh name="Mesh007" geometry={nodes.Mesh007.geometry} material={materials._Body} skeleton={nodes.Mesh007.skeleton} />
              <skinnedMesh name="Mesh007_1" geometry={nodes.Mesh007_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh007_1.skeleton} />
            </group>
            <skinnedMesh name="h_L_eye" geometry={nodes.h_L_eye.geometry} material={materials._Body} skeleton={nodes.h_L_eye.skeleton} />
            <skinnedMesh name="h_L_gland" geometry={nodes.h_L_gland.geometry} material={materials._Body} skeleton={nodes.h_L_gland.skeleton} />
            <skinnedMesh name="h_R_eye" geometry={nodes.h_R_eye.geometry} material={materials._Body} skeleton={nodes.h_R_eye.skeleton} />
            <skinnedMesh name="h_R_gland" geometry={nodes.h_R_gland.geometry} material={materials._Body} skeleton={nodes.h_R_gland.skeleton} />
            <skinnedMesh name="h_TeethDown" geometry={nodes.h_TeethDown.geometry} material={materials._Body} skeleton={nodes.h_TeethDown.skeleton} />
            <skinnedMesh name="h_TeethUp" geometry={nodes.h_TeethUp.geometry} material={materials._Body} skeleton={nodes.h_TeethUp.skeleton} />
            <skinnedMesh name="male_001_Pants" geometry={nodes.male_001_Pants.geometry} material={materials['Material.011']} skeleton={nodes.male_001_Pants.skeleton} />
            <group name="male_001_Shirt">
              <skinnedMesh name="male001Clothed002" geometry={nodes.male001Clothed002.geometry} material={materials['Material.003']} skeleton={nodes.male001Clothed002.skeleton} />
              <skinnedMesh name="male001Clothed002_1" geometry={nodes.male001Clothed002_1.geometry} material={materials['Material.002']} skeleton={nodes.male001Clothed002_1.skeleton} />
            </group>
            <group name="Shoe_left_foot">
              <skinnedMesh name="Cube001" geometry={nodes.Cube001.geometry} material={materials.fabric} skeleton={nodes.Cube001.skeleton} />
              <skinnedMesh name="Cube001_1" geometry={nodes.Cube001_1.geometry} material={materials.rubber} skeleton={nodes.Cube001_1.skeleton} />
            </group>
            <group name="Shoe_left_foot001">
              <skinnedMesh name="Cube002" geometry={nodes.Cube002.geometry} material={materials.fabric} skeleton={nodes.Cube002.skeleton} />
              <skinnedMesh name="Cube002_1" geometry={nodes.Cube002_1.geometry} material={materials.rubber} skeleton={nodes.Cube002_1.skeleton} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/Indian_man_old_anim_hopak-transformed.glb')
