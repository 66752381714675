/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_part2\Indian_boy_baby_anim_chickendance.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Indian_boy_baby_anim_chickendance(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/Indian_boy_baby_anim_chickendance-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <group name="Body_16169_Shape">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.Body} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_1.skeleton} />
            <skinnedMesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials.Toenails} skeleton={nodes.Mesh_2.skeleton} />
          </group>
          <skinnedMesh name="dungaree001" geometry={nodes.dungaree001.geometry} material={materials['Material.001']} skeleton={nodes.dungaree001.skeleton} />
          <skinnedMesh name="EyeReflection_370_Shape" geometry={nodes.EyeReflection_370_Shape.geometry} material={materials.Material} skeleton={nodes.EyeReflection_370_Shape.skeleton} />
          <skinnedMesh name="knitted_sweater_02Mesh" geometry={nodes.knitted_sweater_02Mesh.geometry} material={materials['Material.007']} skeleton={nodes.knitted_sweater_02Mesh.skeleton} />
          <group name="shoe">
            <skinnedMesh name="shoe_1" geometry={nodes.shoe_1.geometry} material={materials.wire_000000000} skeleton={nodes.shoe_1.skeleton} />
            <skinnedMesh name="shoe_2" geometry={nodes.shoe_2.geometry} material={materials.wire_227153153} skeleton={nodes.shoe_2.skeleton} />
          </group>
          <group name="shoe001">
            <skinnedMesh name="shoe001_1" geometry={nodes.shoe001_1.geometry} material={materials.wire_000000000} skeleton={nodes.shoe001_1.skeleton} />
            <skinnedMesh name="shoe001_2" geometry={nodes.shoe001_2.geometry} material={materials.wire_227153153} skeleton={nodes.shoe001_2.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/Indian_boy_baby_anim_chickendance-transformed.glb')
