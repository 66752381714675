/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Latin_woman_middle_anim_gangnam.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Latin_woman_middle_anim_gangnam(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Latin_woman_middle_anim_gangnam-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["ArmatureAction.001"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" scale={0.01}>
          <primitive object={nodes.CC_Base_BoneRoot} />
          <primitive object={nodes.IK_pole_l} />
          <primitive object={nodes.Bone} />
          <primitive object={nodes.IK_pole_r} />
          <primitive object={nodes.Bone001} />
          <skinnedMesh name="CC_Base_Eye" geometry={nodes.CC_Base_Eye.geometry} material={materials['Cornea.001']} skeleton={nodes.CC_Base_Eye.skeleton} />
          <group name="left">
            <skinnedMesh name="Mesh452" geometry={nodes.Mesh452.geometry} material={materials['Material #34']} skeleton={nodes.Mesh452.skeleton} />
            <skinnedMesh name="Mesh452_1" geometry={nodes.Mesh452_1.geometry} material={materials['Material #11']} skeleton={nodes.Mesh452_1.skeleton} />
          </group>
          <group name="right">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['Material #34']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material #11']} skeleton={nodes.Mesh001_1.skeleton} />
          </group>
          <skinnedMesh name="ShtanyExtracted" geometry={nodes.ShtanyExtracted.geometry} material={materials['Material.001']} skeleton={nodes.ShtanyExtracted.skeleton} />
          <skinnedMesh name="sweeterextracted" geometry={nodes.sweeterextracted.geometry} material={materials.Material} skeleton={nodes.sweeterextracted.skeleton} />
          <group name="CC_Base_Body">
            <skinnedMesh name="CC_Base_Body_1" geometry={nodes.CC_Base_Body_1.geometry} material={materials['Std_Skin_Head.001']} skeleton={nodes.CC_Base_Body_1.skeleton} morphTargetDictionary={nodes.CC_Base_Body_1.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_1.morphTargetInfluences} />
            <skinnedMesh name="CC_Base_Body_2" geometry={nodes.CC_Base_Body_2.geometry} material={materials['Std_Skin_Body.001']} skeleton={nodes.CC_Base_Body_2.skeleton} morphTargetDictionary={nodes.CC_Base_Body_2.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_2.morphTargetInfluences} />
            <skinnedMesh name="CC_Base_Body_3" geometry={nodes.CC_Base_Body_3.geometry} material={materials['Std_Skin_Arm.001']} skeleton={nodes.CC_Base_Body_3.skeleton} morphTargetDictionary={nodes.CC_Base_Body_3.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_3.morphTargetInfluences} />
            <skinnedMesh name="CC_Base_Body_4" geometry={nodes.CC_Base_Body_4.geometry} material={materials['Std_Skin_Leg.001']} skeleton={nodes.CC_Base_Body_4.skeleton} morphTargetDictionary={nodes.CC_Base_Body_4.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_4.morphTargetInfluences} />
            <skinnedMesh name="CC_Base_Body_5" geometry={nodes.CC_Base_Body_5.geometry} material={materials['Std_Nails.001']} skeleton={nodes.CC_Base_Body_5.skeleton} morphTargetDictionary={nodes.CC_Base_Body_5.morphTargetDictionary} morphTargetInfluences={nodes.CC_Base_Body_5.morphTargetInfluences} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Latin_woman_middle_anim_gangnam-transformed.glb')
