/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_part2\European_man_middle_anim_chickendance.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function European_man_middle_anim_chickendance(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/European_man_middle_anim_chickendance-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="Bottoms" geometry={nodes.Bottoms.geometry} material={materials.Bottommat} skeleton={nodes.Bottoms.skeleton} />
          <skinnedMesh name="fly_boot__brown_left" geometry={nodes.fly_boot__brown_left.geometry} material={materials['Material.002']} skeleton={nodes.fly_boot__brown_left.skeleton} />
          <skinnedMesh name="fly_boot__brown_left001" geometry={nodes.fly_boot__brown_left001.geometry} material={materials['Material.002']} skeleton={nodes.fly_boot__brown_left001.skeleton} />
          <skinnedMesh name="marn_sold035" geometry={nodes.marn_sold035.geometry} material={materials.Top} skeleton={nodes.marn_sold035.skeleton} />
          <group name="rp_nathan_animated_003_walking_geo">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['rp_nathan_animated_003_mat.001']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh001_1.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/European_man_middle_anim_chickendance-transformed.glb')
