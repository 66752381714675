
import React , { useRef, useState, useEffect,useCallback } from "react";

import recorder from 'react-canvas-recorder';
import { saveAs } from 'file-saver';

import html2canvas from 'html2canvas';

import styled from "styled-components";

import "@tensorflow/tfjs-backend-cpu";
import "@tensorflow/tfjs-backend-webgl";
import * as cocoSsd from "@tensorflow-models/coco-ssd";

import * as network from "@tensorflow-models/mobilenet";


import { Canvas } from "@react-three/fiber";
import { useLoader,extend, useThree, useFrame   } from "@react-three/fiber";
import { Environment, OrbitControls } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader';
import { Suspense } from "react";
import { data, image } from "@tensorflow/tfjs";

import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

import { useGLTF, useAnimations } from '@react-three/drei'



import { Link } from 'react-router-dom';

import { useControls, button, folder } from 'leva'

import { Text } from '@react-three/drei';
import { TextureLoader , MirroredRepeatWrapping} from 'three';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import StyledButton from './StyledButton';

import Button from "@mui/material/Button";
import {Container, Stack} from "@mui/material";


import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook , faCircle, faCalendarTimes, faImage } from '@fortawesome/free-solid-svg-icons'; // Replace 'faIconName' with the icon you want to use


export const useStyles = makeStyles(theme => ({
    beautifulH2: {
      color: '#4A90E2', // Set your desired color
      fontWeight: 600,
      fontSize: '1.5rem', // Adjust the font size as per your preference
      textAlign: 'center',
      borderBottom: `4px solid ${theme.palette.secondary.main}`, // Set your desired border color
      paddingBottom: theme.spacing(1), // Adjust the spacing of the underline
      marginBottom: theme.spacing(3), // Adjust the spacing between the elements
    },
  }));
  
export function FrameWithText(){
    return(
      <>
       <Text
        fontSize = {1.7}
        color = "black"
        anchorX = "center"
        anchorY = "middle"
        position = {[0,1,2.5]}
        >
            Funny cat dancing
        </Text>
      </>  
    );
  }
  
  
  function FrameWithText2() {
  
    /*
    const texmgr = new TextureLoader();
    const texture = texmgr.load("wood.jpg");
    texture.wrapS = texture.wrapT = MirroredRepeatWrapping;
    texture.repeat.set(2, 4);
    const yc = 1;
    */
    const yc= 1;
  
    return (
      
        <>
        {/* Frame */}
        <mesh position={[0, yc, 0]}>
          <planeGeometry args={[25, 2]} />
          <meshBasicMaterial color="white" transparent opacity={0.2} />
        </mesh>
  
        {/* Text */}
        <Text
          fontSize={0.55}
          color="black"
          anchorX="center"
          anchorY="middle"
          position={[0, yc+0.2, 0]}
        >
          My cat
        </Text>
  
        <Text
          fontSize={0.35}
          color="black"
          anchorX="center"
          anchorY="middle"
          position={[0, yc-0.7, 0]}
        >
          Joggling in Saharan safari
        </Text>
        </>
    );
  }
  
export const RandomPerson = (props) => {
      const glb = useLoader(GLTFLoader, "./person" + Math.floor(Math.random() * 6 + 1) + ".glb")
      return (
          <>
            <primitive object={glb.scene} scale={2} position={props.position}  />
          </>
      );
}
  

export const Stadium = (props) => {
    const glb = useLoader(GLTFLoader, "./open_spaces/Stadium.glb")
    return (
        <>
          <primitive object={glb.scene} scale={1.4} position={props.position}  />
        </>
    );
  }
  
export const Hospital = (props) => {
    const glb = useLoader(GLTFLoader, "./interior/Hospital.glb")
    return (
        <>
          <primitive object={glb.scene} scale={1.4} position={props.position} />
        </>
    );
  }
  
export const North_Korea = (props) => {
   
      const glb = useLoader(GLTFLoader, "./countries/North_Korea.glb")
      return (
          <>
            <primitive object={glb.scene} scale={1.4} position={props.position}  />
          </>
      );
    
  }
  
export const Office = (props) => {
    
      const glb = useLoader(GLTFLoader, "./interior/Office.glb")
      return (
          <>
            <primitive object={glb.scene} scale={1.4} position={props.position}  />
          </>
      );
    
  }
  
 export const Animals_penguin = (props) => {
  
    const glb = useLoader(GLTFLoader, "./Animals/Animals_penguin.glb")
    return (
        <>
          <primitive object={glb.scene} scale={1.4} position={props.position}  />
        </>
    );
  }
  
  
 export const Animals_monkey = (props) => {
    const glb = useLoader(GLTFLoader, "./Animals/Animals_monkey.glb")
    return (
        <>
          <primitive object={glb.scene} scale={1.4} position={props.position}  />
        </>
    );
  }
  
 export const Animals_chicken = (props) => {
    const glb = useLoader(GLTFLoader, "./Animals/Animals_chicken.glb")
    return (
        <>
          <primitive object={glb.scene} scale={1.4} position={props.position} />
        </>
    );
  }
  
 export const Animals_cow = (props) => {
    const glb = useLoader(GLTFLoader, "./Animals/Animals_cow.glb")
    return (
        <>
          <primitive object={glb.scene} scale={1.4} position={props.position}  />
        </>
    );
  }
  
  
  
 export const RandomCountry = (props) => {
  
      const glb = useLoader(GLTFLoader, "./country" + Math.floor(Math.random() * 6 + 1) + ".glb")
      return (
          <>
            <primitive object={glb.scene} scale={1.4} position={props.position}  />
          </>
      );
  }


  
 export const Dog = (props) => {
      const gltf = useLoader(GLTFLoader, "./Animals/dog.glb");
      console.log(props.position)
      return (
        <>
          <primitive object={gltf.scene} scale={1.4} position={props.position} />
        </>
      );
  };
  
 export const Asian_man_middle = () => {
      const gltf = useLoader(GLTFLoader, "./Asian_man_middle.glb");
      return (
        <>
          <primitive object={gltf.scene} scale={1.4} />
        </>
      );
  };
  
  export const Prison = (props) => {
      const gltf = useLoader(GLTFLoader, "./interior/Prison.glb");
      return (
        <>
          <primitive object={gltf.scene} scale={1.4} position={props.position} />
        </>
      );
  };
  
  export const Cafe = () => {
      
      const gltf = useLoader(GLTFLoader, "./interior/Cafe.glb")
      return (
        <>
          <primitive object={gltf.scene} scale={1.4} />
        </>
      );
  };
  
  export const European_man_old_anim_robot = () => {
      const gltf = useLoader(GLTFLoader, "./European_man_old_anim_robot.glb");
      return (
        <>
          <primitive object={gltf.scene} scale={1.4} />
        </>
      );
  };
  
  export const African_man_young = (props) => {
    const gltf = useLoader(GLTFLoader, "./static_people/African_man_young_sitting.glb");
    return (
      <>
        <primitive object={gltf.scene} scale={1.4} position={props.position} />
      </>
    );
  }
  
  export const Latin_woman_old = (props) => {
    const gltf = useLoader(GLTFLoader, "./static_people/Latin_woman_old_sitting.glb");
    return (
      <>
        <primitive object={gltf.scene} scale={1.4} position={props.position} />
      </>
    );
  }

// Countries 
export const Agra = (props)=>{
    const gltf = useLoader(GLTFLoader, './static_people/countries/Agra.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }

export const Amsterdam = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Amsterdam.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Baghdad = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Baghdad.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
 export const Brasil = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Brasil.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const China = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/China.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Dubai = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Dubai.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
            
export const Egypt = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Egypt.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Hollywood = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Hollywood.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Iceland = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Iceland.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Japan = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Japan.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
}


export const Kenya = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Kenya.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
}

//
export const Kyiv = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Kyiv.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
}

//
export const London = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/London.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
}

export const Mexico = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Mexico.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
}

export const Moon = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Moon.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Moscow = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Moscow.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const NewYourk = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/NewYourk.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }

export const Norway = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Norway.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }

export const Paris = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Paris.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Rome = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Rome.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Seoul = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Seoul.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Spain = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Spain.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Sweden = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Sweden.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }

export const Sydney = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Sydney.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Venice = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Venice.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }
export const Yerevan = (props)=>{
    const gltf = useLoader(GLTFLoader, './countries/Yerevan.glb');
      return (
                <>
                  <primitive object={gltf.scene} scale={1.4} position={props.position} />
                </>
              );
            }

    
// OPEN SPACES 

export const Airport = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Airport.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const BasketballCourt = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Basketball_court.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}
export const Beach = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Beach.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Jungle = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Jungle.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Lunapark = (props) => {
  // https://drive.google.com/uc?export=download&id=19ztfnO2jdhDtWi_8pPRTQRgoKAIvfAzi
 // https://1drv.ms/u/s!AhZc8q94pkMDhQNRXxvxcQ0ukuUV?e=Bb2CvX
  const gltf = useLoader(GLTFLoader, './open_spaces/Lunapark.glb');
  //const gltf = useLoader(GLTFLoader, '');
  
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const RailwayStation = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Railway_station.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Spaceport = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Spaceport.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Stadium2 = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Stadium_2.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const StreetMarket = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Street_market.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const StreetRain = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Street_rain.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const UfoBase = (props) => {
  const gltf = useLoader(GLTFLoader, './open_spaces/Ufo_base.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Autumn = (props) => {
  const gltf = useLoader(GLTFLoader, './weather/Weather_autumn.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Winter = (props) => {
  const gltf = useLoader(GLTFLoader, './weather/Weather_winter.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Spring = (props) => {
  const gltf = useLoader(GLTFLoader, './weather/Weather_spring.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Summer = (props) => {
  const gltf = useLoader(GLTFLoader, './weather/Weather_summer.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Cafe2 = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Cafe.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Cinema = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Cinema.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const GarbageFactory = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Garbage_factory.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const HappyBirthday = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Happy_birthday.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Hospital2 = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Hospital.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Kitchen = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Kitchen.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const LivingRoom = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Living_room.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={2} position={props.position} />
            </>
         );
}

export const Office2 = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Office.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Prison2 = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Prison.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Theatre = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Theatre.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const Alcoholics = (props) => {
  const gltf = useLoader(GLTFLoader, './interior/Alcoholics_anonymous_club.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const RandomModel = (props) => {
  
  const gltf = useLoader(GLTFLoader, './interior/Alcoholics_anonymous_club.glb');
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
}

export const RandomPersona = (props) => {
  //const [randomNumber] = useState(() => Math.floor(Math.random() * 10) + 1);


  const gltf = useLoader(GLTFLoader, `./static_people/model${props.rn}.glb`);
  return (
            <>
              <primitive object={gltf.scene} scale={1.4} position={props.position} />
            </>
         );
  
}


export const ObjectDetectorContainer = styled.div`
     display:flex;
      flex-direction:column;
      align-items:center;
`;
  
export const DetectorContainer = styled.div`
      width:35vw;
      height:35vh;
      padding:10px;
      border:3px solid;
      box-shadow: 10px 10px 5px lightblue;
  
  `;
  
  export const TargetImg = styled.img`
      width:100%; 
      height:100%;  
  `
  
  export const FileInput = styled.input`
      display:none;
  `
  
  export const SelectButton = styled.button`
    padding: 7px 10px;
    border: 2px solid transparent;
    background-color: darkblue;
    color: #0a0f22;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    margin-top: 2em;
    cursor: pointer;
    transition: all 260ms ease-in-out;
    &:hover {
      background-color: transparent;
      border: 2px solid #fff;
      color: darkblue;
    }
  `;
  
  export const TargetUl = styled.ul`
    content: ${({predicted})  => predicted + " object "};
  `
  
  export const TargetBox = styled.div`
    position: absolute;
    left: ${({ x }) => x + "px"};
    top: ${({ y }) => y + "px"};
    width: ${({ width }) => width + "px"};
    height: ${({ height }) => height + "px"};
    border: 4px solid #1ac71a;
    background-color: transparent;
    z-index: 20;
    &::before {
      content: "${({ classType, score }) => `${classType} ${score.toFixed(1)}%`}";
      color: #1ac71a;
      font-weight: 500;
      font-size: 17px;
      position: absolute;
      top: -1.5em;
      left: -5px;
    }
  `;
  

  const BeautifulH2 = (props) => {
    const classes = useStyles();
  
    return (
      <Typography variant="h2" className={classes.beautifulH2}>
        {props.text}
        
      </Typography>
    );
  };

