import './App.css';
import firebaseConfig from "./firebaseConfig.json";
import { pathnames, ActionPages, AppTemplate, AuthProvider, AuthRoutes, FireactProvider, MainMenu, PublicTemplate, ResetPassword, SignIn, SignUp, UserMenu, UserProfile, UserUpdateEmail, UserUpdateName, UserUpdatePassword, UserDelete } from '@fireactjs/core';
import { BrowserRouter, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
//import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ThreeDRotation from '@mui/icons-material/ThreeDRotation';

import { CircularProgress, Box } from '@mui/material';
import authMethods from "./authMethods.json";


import { ObjectDetector } from "./components/ObjectDetector";

//<LocalFireDepartmentIcon color={logoColor} fontSize={size} />

const Logo_ = ({size, color}) => {
	const logoColor = color || 'warning';
	return (
		<h4><img src="/cat_vector.png" alt="Example" width='40' /></h4>
	);
}

const Logo = ({ size }) => {
	const logoSize = size || 'large';
	const sizes = {
	  small: '30px',
	  medium: '60px',
	  large: '90px'
	};
	const logoSrc = `/cat_vector.png`;
  
	return (
	  <img src={logoSrc} alt="Logo" style={{ height: sizes[logoSize], width: 'auto' }} />
	);
  };

const Loader = ({size}) => {
	let cpSize = "35px";
	switch(size){
		case "small":
			cpSize = "30px";
			break;
		case "medium":
			cpSize = "35px";
			break;
		case "large":
			cpSize = "45px";
			break;
		default:
			cpSize = "35px";
			break;
	}
	return (
		<Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center"}}>
			<CircularProgress color="warning" size={cpSize} />
			<div style={{position: "absolute" }}>
				<Logo size={size} />
			</div>
		</Box>
	);
}

function App() {

	const config = {
		firebaseConfig: firebaseConfig,
		brand: "Animessage",
		pathnames: pathnames,
		authProviders: authMethods
	}

	return (
		<FireactProvider config={config}>
			<AuthProvider>
				<BrowserRouter>
					<Routes>
						<Route element={<AuthRoutes loader={<Loader size="large" />} />} >
							<Route element={<AppTemplate logo={<Logo size="small" />} toolBarMenu={<UserMenu/>} drawerMenu={  <><MainMenu /><span className='mail-name'>Contact:</span><h5 className="mail-name">animessage.scene@gmail.com</h5></>   } />}>
								<Route exact path="/" element={< ObjectDetector />} />
								<Route exact path={pathnames.UserProfile} element={<UserProfile />} />
								<Route exact path={pathnames.UserUpdateEmail} element={<UserUpdateEmail />} />
								<Route exact path={pathnames.UserUpdateName} element={<UserUpdateName />} />
								<Route exact path={pathnames.UserUpdatePassword} element={<UserUpdatePassword />} />
								<Route exact path={pathnames.UserDelete} element={<UserDelete />} />
							</Route>
						</Route>
						<Route element={<PublicTemplate />}>
							<Route path={pathnames.SignIn} element={
								<>
								<h2>Register and use for free</h2>
								<h3>Creation of 3D scene* based on neural networks</h3>
								<h4>(* Based on non-generative AI with a touch of old school raster graphics)</h4>

								<h4>
								<img src="/cat_3d.jpg" alt="Example" width='300' />
								</h4>
							
								
								<SignIn
									logo={<Logo size="large" />}
								/>
								</>
							
							} />
							<Route path={pathnames.SignUp} element={
							<> 
								<h2>Register and use for free</h2>
								<h3>Create a 3D scene* </h3>
								<h4>(* Based on non-generative AI with a touch of old school raster graphics)</h4>

								<h4>
								<img src="/David.png" alt="Example" width='300' />
								</h4>
								<SignUp
									logo={<Logo size="large" />}
								/>

 </>

							} />
							<Route path={pathnames.ResetPassword} element={
								<ResetPassword
									logo={<Logo size="large" />}
								/>
							} />
							<Route path={pathnames.ActionPages} element={
								<ActionPages
									logo={<Logo size="large" />}
								/>
							} />
						</Route>
					</Routes>
				</BrowserRouter>
			</AuthProvider>
		</FireactProvider>
	)
}

export default App;