/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\European_girl_baby_anim_gangnam.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function European_girl_baby_anim_gangnam(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/European_girl_baby_anim_gangnam-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["ArmatureAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.Little_Bree} />
          <group name="Body_16169_Shape">
            <skinnedMesh name="Mesh" geometry={nodes.Mesh.geometry} material={materials.Body} skeleton={nodes.Mesh.skeleton} />
            <skinnedMesh name="Mesh_1" geometry={nodes.Mesh_1.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_1.skeleton} />
            <skinnedMesh name="Mesh_2" geometry={nodes.Mesh_2.geometry} material={materials.Fingernails} skeleton={nodes.Mesh_2.skeleton} />
            <skinnedMesh name="Mesh_3" geometry={nodes.Mesh_3.geometry} material={materials['Material.003']} skeleton={nodes.Mesh_3.skeleton} />
            <skinnedMesh name="Mesh_4" geometry={nodes.Mesh_4.geometry} material={materials['Material.005']} skeleton={nodes.Mesh_4.skeleton} />
          </group>
          <skinnedMesh name="chaussurescroc1" geometry={nodes.chaussurescroc1.geometry} material={materials.chill_shoes} skeleton={nodes.chaussurescroc1.skeleton} />
          <skinnedMesh name="chaussurescroc1001" geometry={nodes.chaussurescroc1001.geometry} material={materials.chill_shoes} skeleton={nodes.chaussurescroc1001.skeleton} />
          <group name="det-platie-m03005">
            <skinnedMesh name="det-platie-m03001" geometry={nodes['det-platie-m03001'].geometry} material={materials['wire_134110008.001']} skeleton={nodes['det-platie-m03001'].skeleton} />
            <skinnedMesh name="det-platie-m03001_1" geometry={nodes['det-platie-m03001_1'].geometry} material={materials['wire_134110008.001']} skeleton={nodes['det-platie-m03001_1'].skeleton} />
            <skinnedMesh name="det-platie-m03001_2" geometry={nodes['det-platie-m03001_2'].geometry} material={materials['wire_028089177.001']} skeleton={nodes['det-platie-m03001_2'].skeleton} />
            <skinnedMesh name="det-platie-m03001_3" geometry={nodes['det-platie-m03001_3'].geometry} material={materials['wire_134110008.001']} skeleton={nodes['det-platie-m03001_3'].skeleton} />
            <skinnedMesh name="det-platie-m03001_4" geometry={nodes['det-platie-m03001_4'].geometry} material={materials['wire_134110008.001']} skeleton={nodes['det-platie-m03001_4'].skeleton} />
          </group>
          <group name="EyeReflection_370_Shape">
            <skinnedMesh name="Mesh010" geometry={nodes.Mesh010.geometry} material={materials.Material} skeleton={nodes.Mesh010.skeleton} />
            <skinnedMesh name="Mesh010_1" geometry={nodes.Mesh010_1.geometry} material={materials['Material.002']} skeleton={nodes.Mesh010_1.skeleton} />
          </group>
          <group name="F_MED_ASN_Starfish_Head_02mo036">
            <skinnedMesh name="F_MED_ASN_Starfish_Head_02md036" geometry={nodes.F_MED_ASN_Starfish_Head_02md036.geometry} material={materials['Material.001']} skeleton={nodes.F_MED_ASN_Starfish_Head_02md036.skeleton} />
            <skinnedMesh name="F_MED_ASN_Starfish_Head_02md036_1" geometry={nodes.F_MED_ASN_Starfish_Head_02md036_1.geometry} material={materials['Material.004']} skeleton={nodes.F_MED_ASN_Starfish_Head_02md036_1.skeleton} />
          </group>
          <group name="Teeth_1564_Shape">
            <skinnedMesh name="Mesh013" geometry={nodes.Mesh013.geometry} material={materials.Teeth} skeleton={nodes.Mesh013.skeleton} />
            <skinnedMesh name="Mesh013_1" geometry={nodes.Mesh013_1.geometry} material={materials.Teeth} skeleton={nodes.Mesh013_1.skeleton} />
            <skinnedMesh name="Mesh013_2" geometry={nodes.Mesh013_2.geometry} material={materials.Teeth} skeleton={nodes.Mesh013_2.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/European_girl_baby_anim_gangnam-transformed.glb')
