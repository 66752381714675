/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims_p2\European_man_teenager_anim_hopak_gopnik.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function European_man_teenager_anim_hopak_gopnik(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims_part2/European_man_teenager_anim_hopak_gopnik-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["ArmatureAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rp_nathan_animated_003_walking" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group name="rp_nathan_animated_003_walking_CTRL">
            <group name="Armature" scale={0.85}>
              <primitive object={nodes.rp_nathan_animated_003_walking_root} />
              <skinnedMesh name="Fleur-de-Lis_black_shoe001" geometry={nodes['Fleur-de-Lis_black_shoe001'].geometry} material={materials.None} skeleton={nodes['Fleur-de-Lis_black_shoe001'].skeleton} />
              <skinnedMesh name="Fleur-de-Lis_black_shoe005" geometry={nodes['Fleur-de-Lis_black_shoe005'].geometry} material={materials.None} skeleton={nodes['Fleur-de-Lis_black_shoe005'].skeleton} />
              <group name="Object002">
                <skinnedMesh name="Mesh009" geometry={nodes.Mesh009.geometry} material={materials['Material.004']} skeleton={nodes.Mesh009.skeleton} />
                <skinnedMesh name="Mesh009_1" geometry={nodes.Mesh009_1.geometry} material={materials['Material.005']} skeleton={nodes.Mesh009_1.skeleton} />
              </group>
              <skinnedMesh name="Pants" geometry={nodes.Pants.geometry} material={materials.lambert2} skeleton={nodes.Pants.skeleton} />
              <group name="rp_nathan_animated_003_walking_geo">
                <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['rp_nathan_animated_003_mat.001']} skeleton={nodes.Mesh001.skeleton} />
                <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh001_1.skeleton} />
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims_part2/European_man_teenager_anim_hopak_gopnik-transformed.glb')
