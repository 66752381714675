/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Arab_woman_middle_anim_accordion.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Arab_woman_middle_anim_accordion(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Arab_woman_middle_anim_accordion-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions.Animation.play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="Blazer_B" geometry={nodes.Blazer_B.geometry} material={materials.Blazer} skeleton={nodes.Blazer_B.skeleton} />
          <group name="CC_Base_Body">
            <skinnedMesh name="CC_Base_Body_1" geometry={nodes.CC_Base_Body_1.geometry} material={materials['Std_Skin_Head.001']} skeleton={nodes.CC_Base_Body_1.skeleton} />
            <skinnedMesh name="CC_Base_Body_2" geometry={nodes.CC_Base_Body_2.geometry} material={materials['Std_Skin_Body.001']} skeleton={nodes.CC_Base_Body_2.skeleton} />
            <skinnedMesh name="CC_Base_Body_3" geometry={nodes.CC_Base_Body_3.geometry} material={materials['Std_Skin_Arm.001']} skeleton={nodes.CC_Base_Body_3.skeleton} />
            <skinnedMesh name="CC_Base_Body_4" geometry={nodes.CC_Base_Body_4.geometry} material={materials['Std_Skin_Leg.001']} skeleton={nodes.CC_Base_Body_4.skeleton} />
            <skinnedMesh name="CC_Base_Body_5" geometry={nodes.CC_Base_Body_5.geometry} material={materials['Std_Nails.001']} skeleton={nodes.CC_Base_Body_5.skeleton} />
          </group>
          <skinnedMesh name="CC_Base_Eye" geometry={nodes.CC_Base_Eye.geometry} material={materials['Cornea.001']} skeleton={nodes.CC_Base_Eye.skeleton} />
          <skinnedMesh name="left001" geometry={nodes.left001.geometry} material={materials['Material #10']} skeleton={nodes.left001.skeleton} />
          <skinnedMesh name="left002" geometry={nodes.left002.geometry} material={materials['Material #10']} skeleton={nodes.left002.skeleton} />
          <group name="Woman_in_suit">
            <skinnedMesh name="Woman_in_suit_1" geometry={nodes.Woman_in_suit_1.geometry} material={materials.Material79262} skeleton={nodes.Woman_in_suit_1.skeleton} />
            <skinnedMesh name="Woman_in_suit_2" geometry={nodes.Woman_in_suit_2.geometry} material={materials.Skirt_FRONT} skeleton={nodes.Woman_in_suit_2.skeleton} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Arab_woman_middle_anim_accordion-transformed.glb')
