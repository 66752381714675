/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\Asian_man_middle_anim_Borat.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function Asian_man_middle_anim_Borat(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/Asian_man_middle_anim_Borat-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["rigAction"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="check_pants" geometry={nodes.check_pants.geometry} material={materials['1_FRONT_4665.004']} skeleton={nodes.check_pants.skeleton} />
          <group name="Line026">
            <skinnedMesh name="Mesh045" geometry={nodes.Mesh045.geometry} material={materials['04 - Default.001']} skeleton={nodes.Mesh045.skeleton} />
            <skinnedMesh name="Mesh045_1" geometry={nodes.Mesh045_1.geometry} material={materials['Material #11.002']} skeleton={nodes.Mesh045_1.skeleton} />
            <skinnedMesh name="Mesh045_2" geometry={nodes.Mesh045_2.geometry} material={materials['07 - Default']} skeleton={nodes.Mesh045_2.skeleton} />
          </group>
          <group name="Line026001">
            <skinnedMesh name="Mesh002" geometry={nodes.Mesh002.geometry} material={materials['04 - Default.001']} skeleton={nodes.Mesh002.skeleton} />
            <skinnedMesh name="Mesh002_1" geometry={nodes.Mesh002_1.geometry} material={materials['Material #11.002']} skeleton={nodes.Mesh002_1.skeleton} />
            <skinnedMesh name="Mesh002_2" geometry={nodes.Mesh002_2.geometry} material={materials['07 - Default']} skeleton={nodes.Mesh002_2.skeleton} />
          </group>
          <group name="rp_nathan_animated_003_walking_geo">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['rp_nathan_animated_003_mat.001']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh001_1.skeleton} />
          </group>
          <skinnedMesh name="shirt_2" geometry={nodes.shirt_2.geometry} material={materials['1_FRONT_4665.005']} skeleton={nodes.shirt_2.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/Asian_man_middle_anim_Borat-transformed.glb')
