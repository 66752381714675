/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 C:\react_app\animessage\public\anims\European_man_young_anim_accordion.glb -T
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function European_man_young_anim_accordion(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/anims/European_man_young_anim_accordion-transformed.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    console.log(actions)
    actions["Animation"].play()
  }, [actions])
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="rig">
          <primitive object={nodes.root} />
          <primitive object={nodes['MCH-torsoparent']} />
          <primitive object={nodes['MCH-hand_ikparentL']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentL']} />
          <primitive object={nodes['MCH-hand_ikparentR']} />
          <primitive object={nodes['MCH-upper_arm_ik_targetparentR']} />
          <primitive object={nodes['MCH-foot_ikparentL']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentL']} />
          <primitive object={nodes['MCH-foot_ikparentR']} />
          <primitive object={nodes['MCH-thigh_ik_targetparentR']} />
          <skinnedMesh name="Bottoms" geometry={nodes.Bottoms.geometry} material={materials.Bottommat} skeleton={nodes.Bottoms.skeleton} />
          <skinnedMesh name="Object002" geometry={nodes.Object002.geometry} material={materials['Material #32']} skeleton={nodes.Object002.skeleton} />
          <group name="rp_nathan_animated_003_walking_geo">
            <skinnedMesh name="Mesh001" geometry={nodes.Mesh001.geometry} material={materials['rp_nathan_animated_003_mat.001']} skeleton={nodes.Mesh001.skeleton} />
            <skinnedMesh name="Mesh001_1" geometry={nodes.Mesh001_1.geometry} material={materials['Material.001']} skeleton={nodes.Mesh001_1.skeleton} />
          </group>
          <skinnedMesh name="SneakerLeft001" geometry={nodes.SneakerLeft001.geometry} material={materials.wire_028028032} skeleton={nodes.SneakerLeft001.skeleton} />
          <skinnedMesh name="SneakerLeft002" geometry={nodes.SneakerLeft002.geometry} material={materials.wire_028028032} skeleton={nodes.SneakerLeft002.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/anims/European_man_young_anim_accordion-transformed.glb')
